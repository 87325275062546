import { API_KEY, ENDPOINT_7PAY, PID } from './payment.env'
import md5 from './md5'
import QueryString from 'qs'

function stripslashes(str) {
  // if (!str?.replace) return str
  return (str || '').toString().replace(/\\/g, '')
}

const getSign = (hashkey, searchParams) => {
  const datas = {
    name: searchParams.name,
    money: searchParams.money,
    type: searchParams.type,
    pid: searchParams.pid,
    out_trade_no: searchParams.out_trade_no,
    notify_url: searchParams.notify_url,
    return_url: searchParams.return_url,
    sign_type: 'MD5'
  }

  const pre = {}
  for (const key in datas) {
    const data = datas[key]
    if (data === null || data === '') continue
    if (key === 'sign' || key === 'sign_type') continue
    pre[key] = stripslashes(data)
  }

  let arg = ''
  const keys = Object.keys(pre)
  const qty = keys.length

  keys.sort().forEach((key, index) => {
    arg += key + '=' + pre[key]
    if (index < qty - 1) {
      arg += '&'
    }
  })

  return md5(arg + hashkey).toLowerCase()
}

/**
 *
 * @param {*} no order id
 * @param {*} wallet buyer wallet address
 * @param {*} amount number of GOKOO to buy - format decimal (6), ex: 1000000 = 1 GOKOO
 * @returns string - endpoint that return order status
 */
export const generateNotifyUrl = ({ no, wallet, amount }) => {
  return `${
    process.env.REACT_APP_CHECKOUT_ENDPOINT
  }/notifyGkooAlipay?${QueryString.stringify({
    no,
    wallet,
    amount,
    sign: getNotifySign(API_KEY, { no, wallet, amount })
  })}`
}

export const generateNotifyUrlForFreeOrder = () => {
  return `${process.env.REACT_APP_CHECKOUT_ENDPOINT}/notifyFreeListing`
}

export const getNotifySign = (hashkey, searchParams) => {
  const datas = {
    amount: searchParams.amount,
    no: searchParams.no,
    wallet: searchParams.wallet
  }

  const pre = {}
  for (const key in datas) {
    const data = datas[key]
    if (data === null || data === '' || data === undefined) continue
    if (key === 'sign' || key === 'sign_type') continue
    pre[key] = stripslashes(data)
  }

  let arg = ''
  const keys = Object.keys(pre)
  const qty = keys.length

  keys.sort().forEach((key, index) => {
    arg += key + '=' + pre[key]
    if (index < qty - 1) {
      arg += '&'
    }
  })
  return md5(arg + '&key=' + hashkey).toLowerCase()
}

export const gen7PayLink = (
  orderId,
  convertedAmountInCnYuan,
  depositGokooAmount,
  notifyUrl,
  returnUrl
) => {
  const alipayUrl = new URL(ENDPOINT_7PAY)

  const params = {
    name: `充值 ${depositGokooAmount} GOKOO`,
    money: convertedAmountInCnYuan,
    type: 'alipay',
    pid: PID,
    out_trade_no: orderId,
    notify_url: notifyUrl,
    return_url: returnUrl
  }

  Object.keys(params).forEach((key) => {
    alipayUrl.searchParams.append(key, params[key])
  })

  const sig = getSign(API_KEY, params)
  alipayUrl.searchParams.append('sign', sig)

  return alipayUrl
}

export const supportedPaymentMethod = {
  stripe: 'STRIPE',
  alipay: 'ALIPAY',
  free: 'FREE'
}

export const OrderTypes = {
  deposit: 'DEPOSIT',
  withdraw: 'WITHDRAW'
}

/**
 *
 * @deposit { paymentMethod, depositAmount, goBackUrl }
 * @withdraw { withdrawAmount, email }
 */
export const saveOrderInfoLocalStorage = (orderId, type, data) => {
  const orderStore = JSON.parse(localStorage.getItem('order') || '{}')
  if (type === OrderTypes.deposit) {
    orderStore[orderId] = {
      depositAmount: data.depositAmount,
      paymentMethod: data.paymentMethod,
      goBackUrl: data.goBackUrl
    }
  }

  if (type === OrderTypes.withdraw) {
    orderStore[orderId] = {
      withdrawAmount: data.withdrawAmount,
      receiveAmountInUsd: data.receiveAmountInUsd,
      email: data.email,
      txHash: data.txHash
    }
  }

  localStorage.setItem('order', JSON.stringify(orderStore))
}
