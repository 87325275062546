import { IconButton } from '@mui/material'
import Image from '@components/Image'
import React from 'react'

const SocialLinkIcon = ({ to, src }) => (
  <a href={to} target="_blank" rel="noreferrer">
    <IconButton sx={{ padding: 0 }}>
      <Image
        src={src}
        sx={{
          width: 36,
          height: 36
        }}
      />
    </IconButton>
  </a>
)

export default SocialLinkIcon
