import { TOAST_TYPE } from '@common/constants/common'
import axiosInstance from '@utils/axios'
import { showToast } from '@utils/toast'
import { isEmpty } from 'lodash'
import { useEffect, useRef, useState } from 'react'

export const useBlacklistCreators = () => {
  const [blacklistCreatorWallets, setBlacklistCreatorWallets] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchBlacklistCreators = async () => {
    const res = await axiosInstance.get('/blacklistCreators')

    if (res.status !== 200 || !res.data) {
      showToast('Could not fetch the account info.', TOAST_TYPE.ERROR)
      return
    }

    setBlacklistCreatorWallets(
      isEmpty(res.data?.address)
        ? []
        : res.data.address.map((address) => address.toLowerCase())
    )

    setLoading(false)
  }

  const mounted = useRef()

  useEffect(() => {
    if (mounted.current) return
    mounted.current = true
    fetchBlacklistCreators()
    return () => {
      mounted.current = false
    }
  }, [])

  return {
    blacklistCreatorWallets: [
      ...blacklistCreatorWallets,
      // add default to avoid if blacklistCreatorWallets empty then query always empty in result
      '0x0000000000000000000000000000000000000000'
    ],
    loading
  }
}
