import React, { useCallback, useEffect, useState } from 'react'
import {
  Paper,
  Typography,
  Box,
  Stack,
  Button,
  TextField,
  Alert
} from '@mui/material'
import Image from '@components/Image'
import PayPalIconPNG from '@assets/icons/paypal.png'
import { useTheme } from '@emotion/react'
import usePersistState from '@common/hooks/usePersistState'
import PayPalEmail from './PayPalEmail'
import { isEmpty, uniq } from 'lodash'
import { isValidEmail } from '@utils/common'

const PayPalIcon = () => {
  return <Image width={30} height={30} src={PayPalIconPNG} />
}

const PayoutWithPaypal = ({ checked, paypalEmail, setPaypalEmail }) => {
  const theme = useTheme()
  const [isAdding, setAdding] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')

  const [error, setError] = useState('')

  const [paypalEmails, setPaypalEmails] = usePersistState({
    name: 'ALL_PAYPAL_EMAILS',
    initialState: []
  })

  useEffect(() => {
    if (!paypalEmail && !isEmpty(paypalEmails)) {
      setPaypalEmail(paypalEmails[0])
    }
  }, [paypalEmail, paypalEmails, setPaypalEmail])

  const onRemoveEmail = useCallback(
    (email) => {
      const updatedPaypalEmails = paypalEmails.filter(
        (emailItem) => emailItem !== email
      )
      setPaypalEmails(updatedPaypalEmails)

      if (!updatedPaypalEmails.includes(paypalEmail)) {
        if (isEmpty(updatedPaypalEmails)) {
          setPaypalEmail('')
          return
        }

        setPaypalEmail(updatedPaypalEmails[0])
      }
    },
    [paypalEmail, paypalEmails, setPaypalEmail, setPaypalEmails]
  )

  const onSaveNewEmail = useCallback(() => {
    if (!isValidEmail(newEmail)) {
      setError('Email is invalid!')
      return
    }

    if (paypalEmails.includes(newEmail.toLowerCase())) {
      setError('The email is already existed!')
      return
    }

    setError('')

    setPaypalEmail(newEmail)
    setPaypalEmails(uniq([...paypalEmails, newEmail.toLowerCase()]))
    setAdding(false)
  }, [newEmail, paypalEmails, setPaypalEmail, setPaypalEmails])

  return (
    <Box>
      <Box display="flex" gap={1} flexDirection="column" marginBottom={2}>
        {paypalEmails.map((email) => (
          <PayPalEmail
            key={email}
            email={email}
            checked={email === paypalEmail}
            onRemove={() => {
              onRemoveEmail(email)
            }}
            onClick={() => {
              setPaypalEmail(email)
            }}
          />
        ))}
      </Box>

      {!isAdding && (
        <Paper
          sx={{
            cursor: 'pointer',
            flex: 1,
            paddingX: { xs: 2, md: 3 },
            paddingY: 2,
            borderRadius: 4,
            backgroundColor: theme.palette.white[300],
            border: '3px solid transparent',
            opacity: 1,
            borderColor: 'transparent'
          }}
          onClick={() => {
            setNewEmail('')
            setConfirmEmail('')
            setAdding(true)
          }}
        >
          <Box direction="row" display="flex" justifyContent="space-between">
            <Box
              display="flex"
              flexDirection="column"
              sx={{ marginLeft: '22px' }}
              justifyContent="center"
            >
              <Typography variant="body2Medium" sx={{ fontSize: 16 }}>
                Add Paypal Account
              </Typography>
            </Box>
            <Box justifyContent="center">
              <PayPalIcon />
            </Box>
          </Box>
        </Paper>
      )}

      {isEmpty(paypalEmails) && !isAdding && (
        <Alert severity="info" sx={{ marginTop: 2 }}>
          You need a Paypal account to withdraw GOKOO for USD.
        </Alert>
      )}

      {isAdding && (
        <Stack direction="row" alignItems="center" mt={2}>
          <Paper
            sx={{
              cursor: 'pointer',
              flex: 1,
              paddingX: { xs: 2, md: 3 },
              paddingY: 2,
              borderRadius: 4,
              backgroundColor: theme.palette.white[300],
              border: '3px solid transparent',
              borderColor: checked ? '#fa4242cf' : 'transparent'
            }}
          >
            <Box
              flexDirection="column"
              display="flex"
              gap={3}
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body2Medium" sx={{ fontSize: 16 }}>
                  New Paypal account
                </Typography>
                <Box justifyContent="center">
                  <PayPalIcon />
                </Box>
              </Box>

              <TextField
                size="small"
                required
                label="Paypal email"
                value={newEmail}
                onChange={(event) => {
                  setNewEmail(event.target.value)
                }}
              />
              <TextField
                size="small"
                required
                label="Confirm email"
                value={confirmEmail}
                onChange={(event) => {
                  setConfirmEmail(event.target.value)
                }}
              />
              {!!error && <Alert severity="error">{error}</Alert>}
              <Box display="flex" justifyContent="flex-end" gap={2}>
                <Button
                  variant="outlined"
                  color="success"
                  size="small"
                  onClick={() => {
                    setAdding(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={!newEmail || newEmail !== confirmEmail}
                  onClick={onSaveNewEmail}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Paper>
        </Stack>
      )}
    </Box>
  )
}

export { PayoutWithPaypal }
