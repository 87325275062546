import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { React, useState } from 'react'
import { Alert, Snackbar, Box, CircularProgress, Button } from '@mui/material'

export default function CheckoutForm(props) {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)

  const handleClose = () => {
    setOpenAlert(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!stripe || !elements) {
      return
    }
    setIsProcessing(true)
    props.onPaymentStart?.()

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/completion`
      },
      redirect: 'if_required'
    })

    if (
      error &&
      (error.type === 'card_error' || error.type === 'validation_error')
    ) {
      setMessage(error.message)
      setOpenAlert(true)
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      setMessage(null)
      props.onPaymentSucceeded({ orderId: paymentIntent.id })
    } else {
      setMessage('An unexpected error occured.')
      setOpenAlert(true)
    }

    setIsProcessing(false)
    props.onPaymentEnd?.()
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit} style={{ height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <PaymentElement id="payment-element" />
        <Box>
          {isProcessing && (
            <Box
              position="absolute"
              top="45%"
              left="45%"
              transform="translate(-50%, -50%)"
            >
              <CircularProgress />
            </Box>
          )}

          {message && (
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: '100%' }}
              >
                {message}
              </Alert>
            </Snackbar>
          )}

          <Box sx={{ display: 'flex', marginTop: '20px', gap: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="success"
              size="large"
              sx={{ flex: 1 }}
              onClick={async () => {
                await elements.submit()
              }}
            >
              <span
                style={{
                  margin: '0px 26px'
                }}
              >
                <span
                  id="button-text"
                  style={{
                    marginRight: 1,
                    fontSize: 20
                  }}
                >
                  Submit
                </span>
              </span>
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  )
}
