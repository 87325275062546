import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

export const formatDate = (date, format = 'DD/MM/YYYY') => {
  return dayjs(date).format(format)
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}

export const format = (date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate())
    ].join('-') +
    ' ' +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(':')
  )
}
