import React, { useState } from 'react'
import EnterAmount from './components/steps/EnterAmount'
import Payment from './components/steps/Payment'
import FundsTabList from '../TabList'

const DEPOSIT_STEPS = {
  ENTER_AMOUNT: 1,
  PAYMENT_PROCESS: 2
}

export default function DepositForm() {
  const [step, setStep] = React.useState(DEPOSIT_STEPS.ENTER_AMOUNT)
  const [amount, setAmount] = useState(100)

  if (step === DEPOSIT_STEPS.ENTER_AMOUNT) {
    return (
      <>
        <FundsTabList />

        <EnterAmount
          amount={amount}
          setAmount={setAmount}
          onNext={() => {
            setStep(DEPOSIT_STEPS.PAYMENT_PROCESS)
          }}
        />
      </>
    )
  }
  if (step === DEPOSIT_STEPS.PAYMENT_PROCESS) {
    return <Payment amount={amount} setStep={setStep} />
  }

  return null
}
