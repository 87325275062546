import { toast } from 'react-toastify'
import { TOAST_TYPE } from '@common/constants/common'

export const showToast = (message, type, id) => {
  const options = {
    ...(id ? { toastId: id } : undefined),
    position: 'bottom-right',
    autoClose: 9000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: false,
    pauseOnHover: false,
    pauseOnFocusLoss: false
  }

  switch (type) {
    case TOAST_TYPE.ERROR:
      toast.error(message, options)
      break
    case TOAST_TYPE.SUCCESS:
      toast.success(message, options)
      break
    case TOAST_TYPE.INFO:
      toast.info(message, options)
      break
    default:
      toast(message, options)
      break
  }
}
