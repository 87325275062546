import { GKOOIcon } from '@components/icons/GKOOIcon'
import { Stack, Typography } from '@mui/material'
import { formatGokooDisplay } from '@utils/ethers'
import React from 'react'

export default function GOKOOBalance({ balance }) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
      <Typography color="primary" variant="caption" sx={{ height: '20px' }}>
        Balance:
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-end"
        gap={0.5}
      >
        <GKOOIcon size={18} />
        <Typography
          color="primary"
          variant="h6"
          sx={{
            fontSize: 16,
            lineHeight: '30px',
            height: 24,
            letterSpacing: 1,
            fontWeight: 600,
            color: '#333'
          }}
        >
          {formatGokooDisplay(+balance)}
        </Typography>
      </Stack>
    </Stack>
  )
}
