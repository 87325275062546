import { ENV } from '@common/constants/common'
import TagManager from 'react-gtm-module'

const isProd = process.env.REACT_APP_ENV === ENV.PROD

if (isProd) {
  const tagManagerArgs = {
    gtmId: 'GTM-TCB7W2FR'
  }
  TagManager.initialize(tagManagerArgs)
}
