import { createTheme, css } from '@mui/material'
import SuisseBPIntlLight from '@assets/fonts/Suisse_BP_Intl_Light.otf'
import SuisseBPIntlMedium from '@assets/fonts/Suisse_BP_Intl_Medium.otf'
import SatoshiWoff2 from '@assets/fonts/Satoshi-Regular.woff2'
import SatoshiWoff from '@assets/fonts/Satoshi-Regular.woff'
import SatoshiTtf from '@assets/fonts/Satoshi-Regular.ttf'
import SatoshiBlackWoff2 from '@assets/fonts/Satoshi-Black.woff2'
import SatoshiBlackWoff from '@assets/fonts/Satoshi-Black.woff'
import SatoshiBlackTtf from '@assets/fonts/Satoshi-Black.ttf'
import PingFangSC from '@assets/fonts/PingFang-SC-Regular.ttf'
import { darkPalette, lightPalette } from './palette'
import { font } from '@common/theme/font'

export const defaultTheme = createTheme({})

export const mobileLandscapeQuery = `${defaultTheme.breakpoints.down(
  'md'
)} and (max-height: 500px) and (orientation: landscape)`

export const getDesignTokens = (mode) => {
  const palette = mode === 'light' ? lightPalette : darkPalette

  const overriddenButtonStyles =
    mode === 'dark'
      ? {
          '&.Mui-disabled': {
            background: 'none',
            backgroundColor: palette.white[700],

            '& > svg path': {
              fill: palette.white[400]
            }
          },

          fontSize: 20,
          lineHeight: 1.6,

          [defaultTheme.breakpoints.up('xs')]: {
            minWidth: 91,
            height: 40,
            fontSize: 14,
            padding: '12px 24px'
          },

          [mobileLandscapeQuery]: {
            minWidth: '91px !important',
            minHeight: '40px !important',
            fontSize: '14px !important',
            padding: '12px 24px !important'
          },

          [defaultTheme.breakpoints.up('sm')]: {
            minWidth: 188,
            height: 56,
            fontSize: 20,
            padding: '16px 48px'
          }
        }
      : {}
  return {
    palette: {
      mode,
      ...palette
    },
    typography: {
      fontFamily: font.suisse,
      body1Medium: {
        fontWeight: 300,
        fontSize: 16,
        lineHeight: 1.4,
        letterSpacing: '0.01em'
      },
      body2Medium: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 1.4,
        letterSpacing: '0.01em'
      },
      body1Large: {
        fontWeight: 300,
        fontSize: 20,
        lineHeight: 1.4,
        letterSpacing: '0.01em'
      },
      body2Large: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: 1.4,
        letterSpacing: '0.01em'
      },
      subtitle1Medium: {
        fontWeight: 300,
        fontSize: 16,
        lineHeight: 1.4,
        letterSpacing: '0.01em'
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: css`
          @font-face {
            font-family: ${font.suisse};
            src: url(${SuisseBPIntlLight}) format('opentype');
            font-style: normal;
            font-display: swap;
            font-weight: 300;
          }

          @font-face {
            font-family: ${font.suisse};
            src: url(${SuisseBPIntlMedium}) format('opentype');
            font-style: normal;
            font-display: swap;
            font-weight: 500;
          }

          @font-face {
            font-family: ${font.satoshi};
            src: url(${SatoshiWoff2}) format('woff2'),
              url(${SatoshiWoff}) format('woff'),
              url(${SatoshiTtf}) format('truetype');
            font-weight: 400;
            font-display: swap;
            font-style: normal;
          }

          @font-face {
            font-family: ${font.satoshi};
            src: url(${SatoshiBlackWoff2}) format('woff2'),
              url(${SatoshiBlackWoff}) format('woff'),
              url(${SatoshiBlackTtf}) format('truetype');
            font-weight: 900;
            font-display: swap;
            font-style: normal;
          }

          @font-face {
            font-family: ${font.chinese};
            src: url(${PingFangSC}) format('truetype');
            font-style: normal;
            font-weight: 400;
          }

          html,
          body {
            font-smooth: always;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-color: ${palette.background.default};
            scroll-behavior: smooth;
          }

          a {
            //color: inherit !important;
            text-decoration: none !important;
          }

          input[type='number']::-webkit-inner-spin-button,
          input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          ::-webkit-scrollbar {
            width: 8px;
          }

          ::-webkit-scrollbar-track {
            background: ${palette.white[700]};
          }

          ::-webkit-scrollbar-thumb {
            background: ${palette.white[300]};
          }

          .Toastify__toast-theme--colored.Toastify__toast--error {
            background: ${defaultTheme.palette.error.main} !important;
          }

          .-cbwsdk-extension-dialog-box-bottom-description-region > body {
            font-family: ${font.suisse} !important;
            font-size: 20px !important;
            line-height: 1.5 !important;
          }

          .-cbwsdk-snackbar-instance-header,
          .-cbwsdk-snackbar-instance-menu {
            background-color: ${palette.background.paper} !important;
            border: 1px solid ${palette.white[900]} !important;
          }

          .-cbwsdk-snackbar-instance-menu-item-info,
          .-cbwsdk-snackbar-instance-header-message,
          .-cbwsdk-extension-dialog-box h2,
          .-cbwsdk-extension-dialog-box body {
            color: ${palette.text.primary} !important;
          }

          .-cbwsdk-snackbar-instance-menu-item:hover {
            background: ${palette.background.contained} !important;
          }

          .-cbwsdk-snackbar-instance-header .-gear-icon {
            -webkit-filter: invert(100%);
            filter: invert(100%);
          }

          .-cbwsdk-extension-dialog-box-bottom.light,
          .-cbwsdk-extension-dialog-box-top.light,
          .-cbwsdk-extension-dialog-box-top-info-region.light {
            background-color: ${palette.background.paper} !important;
          }

          .marquee {
            display: block;
            margin-right: 4px;
          }
        `
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: palette.background.default,
            boxShadow: '0px 2px 24px rgba(22, 20, 24, 0.05)'
          }
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(0, 0, 0, 0.12)'
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontWeight: 500,
            lineHeight: 1.4,

            [defaultTheme.breakpoints.up('xs')]: {
              fontSize: 32
            },

            [mobileLandscapeQuery]: {
              fontSize: '32px !important'
            },

            [defaultTheme.breakpoints.up('sm')]: {
              fontSize: 48,
              lineHeight: 1.4
            }
          },
          h2: {
            fontWeight: 500,
            lineHeight: 1,

            [defaultTheme.breakpoints.up('xs')]: {
              fontSize: 24
            },

            [mobileLandscapeQuery]: {
              fontSize: '24px !important'
            },

            [defaultTheme.breakpoints.up('sm')]: {
              fontSize: 36
            }
          },
          h3: {
            fontWeight: 500,
            lineHeight: 1,

            [defaultTheme.breakpoints.up('xs')]: {
              fontSize: 24
            },

            [mobileLandscapeQuery]: {
              fontSize: '24px !important'
            },

            [defaultTheme.breakpoints.up('sm')]: {
              fontSize: 24
            }
          },
          h4: {
            fontWeight: 500,
            lineHeight: 1,
            fontSize: 20
          },
          body1: {
            fontWeight: 400,
            lineHeight: 1.6,
            letterSpacing: '0.01em',

            [defaultTheme.breakpoints.up('xs')]: {
              fontSize: 16
            },

            [mobileLandscapeQuery]: {
              fontSize: '16px !important'
            },

            [defaultTheme.breakpoints.up('sm')]: {
              fontSize: 20
            }
          },
          body2: {
            fontWeight: 300,
            lineHeight: 1.5,
            color: palette.text.secondary,

            [defaultTheme.breakpoints.up('xs')]: {
              fontSize: 16
            },

            [mobileLandscapeQuery]: {
              fontSize: '16px !important'
            },

            [defaultTheme.breakpoints.up('sm')]: {
              fontSize: 20
            }
          },
          subtitle1: {
            fontWeight: 300,
            lineHeight: 1.5,
            color: palette.text.secondary,

            [defaultTheme.breakpoints.up('xs')]: {
              fontSize: 12
            },

            [mobileLandscapeQuery]: {
              fontSize: '12px !important'
            },

            [defaultTheme.breakpoints.up('sm')]: {
              fontSize: 20
            }
          },
          caption: {
            fontSize: 16
          },
          body1Large: {
            [defaultTheme.breakpoints.up('xs')]: {
              fontSize: 16
            },

            [mobileLandscapeQuery]: {
              fontSize: '16px !important'
            },

            [defaultTheme.breakpoints.up('sm')]: {
              fontSize: 20
            }
          },
          body4: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 1.4,
            letterSpacing: '0.01em'
          }
        }
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            width: '100%'
          },
          maxWidthLg: {
            [defaultTheme.breakpoints.up('xs')]: {
              paddingLeft: '32px',
              paddingRight: '32px'
            },

            [mobileLandscapeQuery]: {
              paddingLeft: '32px !important',
              paddingRight: '32px !important'
            },

            [defaultTheme.breakpoints.up('md')]: {
              paddingLeft: '120px',
              paddingRight: '120px'
            }

            // [defaultTheme.breakpoints.up('lg')]: {
            //   maxWidth: 820
            // }
          },

          maxWidthXl: {
            [defaultTheme.breakpoints.up('xs')]: {
              paddingLeft: '16px',
              paddingRight: '16px'
            },

            [mobileLandscapeQuery]: {
              paddingLeft: '16px !important',
              paddingRight: '16px !important'
            },

            [defaultTheme.breakpoints.up('sm')]: {
              paddingLeft: '32px',
              paddingRight: '32px'
            },

            [defaultTheme.breakpoints.up('lg')]: {
              paddingLeft: 0,
              paddingRight: 0
            },

            [defaultTheme.breakpoints.up('xl')]: {
              paddingLeft: '80px',
              paddingRight: '80px'
            },

            [defaultTheme.breakpoints.up('lg')]: {
              maxWidth: 1024
            },

            [defaultTheme.breakpoints.up('xl')]: {
              maxWidth: 1440
            }
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'none'
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: palette.primary.main,
            '&.Mui-checked': {
              color: palette.error.main
            }
          }
        }
      },

      MuiButton: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              background: palette.white[500],
              // color: '#FFF',
              '& > svg path': {
                // fill: '#FFF'
              }
            },
            borderRadius: 30,
            textTransform: 'none',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none'
            },
            ...overriddenButtonStyles
            // fontSize: 20,
            // lineHeight: 1.6,

            // [defaultTheme.breakpoints.up('xs')]: {
            //   minWidth: 91,
            //   height: 40,
            //   fontSize: 14,
            //   padding: '12px 24px'
            // },

            // [mobileLandscapeQuery]: {
            //   minWidth: '91px !important',
            //   minHeight: '40px !important',
            //   fontSize: '14px !important',
            //   padding: '12px 24px !important'
            // },

            // [defaultTheme.breakpoints.up('sm')]: {
            //   minWidth: 188,
            //   height: 56,
            //   fontSize: 20,
            //   padding: '16px 48px'
            // }
          },
          containedPrimary: {
            '& .MuiButton-endIcon path': {
              fill: palette.text.contrastText
            }
          },
          containedSuccess: {
            background: palette.gradient.brand
            // color: '#FFFFFF'
          },
          outlinedSuccess: {
            background: `linear-gradient(${palette.background.default}, ${palette.background.default}) padding-box,
              ${palette.gradient.brand} border-box`,
            color: palette.text.primary,
            borderColor: 'transparent',
            '&:hover': {
              borderColor: 'transparent'
            }
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            [defaultTheme.breakpoints.up('xs')]: {
              '& .MuiInput-underline .MuiInput-input': {
                padding: '14px 0 12px'
              }
            },

            [mobileLandscapeQuery]: {
              '& .MuiInput-underline .MuiInput-input': {
                padding: '14px 0 12px !important'
              }
            },

            [defaultTheme.breakpoints.up('sm')]: {
              '& .MuiInput-underline .MuiInput-input': {
                padding: '14px 0 20px'
              }
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            lineHeight: 1,
            fontWeight: 300,

            [defaultTheme.breakpoints.up('xs')]: {
              fontSize: 14
            },

            [mobileLandscapeQuery]: {
              fontSize: '14px !important'
            },

            [defaultTheme.breakpoints.up('sm')]: {
              fontSize: 20
            },

            '&:after': {
              borderBottom: `1px solid ${palette.primary.main}`
            }
          }
        }
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: palette.background.default,
            backgroundImage: 'none'
          }
        }
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            margin: 0
          },
          primary: {
            fontSize: 18
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            marginTop: '6px'
          }
        }
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            gap: '10px',
            borderRadius: 'none'
          },
          grouped: {
            borderTopLeftRadius: '8px !important',
            borderBottomLeftRadius: '8px !important',
            borderTopRightRadius: '8px !important',
            borderBottomRightRadius: '8px !important'
          }
        }
      },
      MuiImage: {
        defaultProps: {
          crossOrigin: 'anonymous'
        }
      },
      MuiAvatar: {
        defaultProps: {
          crossOrigin: 'anonymous'
        }
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            width: 69,
            height: 36,
            border: 'none',
            borderRadius: '8px',
            textTransform: 'capitalize',
            fontFamily: font.chinese,
            fontWeight: 500,
            fontSize: 16,
            lineHeight: 1.5,
            // background: palette.white[800],
            // color: palette.white[300],

            '&.Mui-selected': {
              color: palette.primary.main
              // background: palette.white[600]
            },

            '&.Mui-disabled': {
              // backgroundColor: palette.white[500],
              border: 'none'
            }
          }
        }
      }
    }
  }
}
