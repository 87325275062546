import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'

import { AppLayoutProvider } from '../../common/contexts/AppLayoutContext'
import Footer from './components/Footer'
import { lazy } from 'react'

const Sidebar = lazy(() => import('./components/Sidebar'))

const drawerWidth = 250

function ResponsiveDrawer(props) {
  return (
    <AppLayoutProvider>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Sidebar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            paddingBottom: { xs: '220px', md: 20 },
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            minHeight: '100vh',
            position: 'relative'
          }}
        >
          {props.children}
          <Footer />
        </Box>
      </Box>
    </AppLayoutProvider>
  )
}

export default ResponsiveDrawer
