const white = {
  900: '#3c3c3c',
  800: '#606060',
  700: '#818181',
  600: '#979797',
  500: '#c2c2c2',
  400: '#dedede',
  300: '#f0f0f0',
  200: '#f5f5f5',
  100: '#fafafa'
}

const black = {
  500: '#7d7d7d',
  600: '#575757',
  700: '#454545',
  800: '#282828',
  900: '#030303'
}

export const darkPalette = {
  primary: {
    main: '#FFFFFF'
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF95',
    disabled: '#FFFFFF50',
    darker: '#FFFFFF90',
    contrastText: '#000000',
    navColor: '#FFFFFF'
  },
  error: {
    main: '#FA4242'
  },
  background: {
    default: '#161418',
    paper: '#161418',
    contained: '#242226'
  },
  white,
  black,
  gradient: {
    brand: 'linear-gradient(96.46deg, #FFD056 0.22%, #FA4242 58.03%)'
  }
}

export const lightPalette = {
  primary: {
    main: black[900]
  },
  text: {
    primary: black[900],
    secondary: black[700],
    disabled: black[500],
    darker: '#FFFFFF90',
    contrastText: '#000000',
    navColor: '#000000'
  },
  error: {
    main: '#FA4242'
  },
  background: {
    default: white[100],
    paper: white[100]
    // contained: '#242226'
  },
  white,
  black,
  gradient: {
    brand: 'linear-gradient(96.46deg, #FFD056 0.22%, #FA4242 58.03%)'
  }
}
