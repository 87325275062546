import { ACCOUNT_TIER, TOAST_TYPE } from '@common/constants/common'
import { useQuery } from '@tanstack/react-query'
import axiosInstance from '@utils/axios'
import { showToast } from '@utils/toast'

/**
 * Fetch account tier
 * @param {string} account
 */
export const useAccountTier = (account) => {
  return useQuery({
    queryKey: ['account-tier', account],
    queryFn: async () => {
      let tier = ACCOUNT_TIER.FREE
      const res = await axiosInstance.get('/proCreators')

      if (res.status !== 200 || !res.data) {
        const err = 'Could not fetch the account info.'
        showToast(err, TOAST_TYPE.ERROR)
        return tier
      }

      if (
        Array.isArray(res.data.address) &&
        res.data.address
          .map((x) => x.toLowerCase())
          .includes(account.toLowerCase())
      ) {
        tier = ACCOUNT_TIER.PRO
      }

      return tier
    },
    enabled: !!account,
    retry: true,
    retryDelay: 5000
  })
}
