import React, { useState } from 'react'
import { Paper, Typography, Box, Stack, IconButton } from '@mui/material'
import Image from '@components/Image'
import PayPalIconPNG from '@assets/icons/paypal.png'
import { useTheme } from '@emotion/react'
import { CloseOutlined } from '@mui/icons-material'

export default function PayPalEmail({ checked, email, onClick, onRemove }) {
  const theme = useTheme()
  const [isShowRemove, setIsShowRemove] = useState(false)

  return (
    <Stack
      direction="row"
      alignItems="center"
      position="relative"
      onMouseEnter={() => {
        setIsShowRemove(true)
      }}
      onMouseLeave={() => {
        setIsShowRemove(false)
      }}
    >
      {isShowRemove && (
        <IconButton
          aria-label="delete"
          onClick={onRemove}
          sx={{
            position: 'absolute',
            background: theme.palette.white[500],
            right: -8,
            top: -8,
            width: 30,
            height: 30
          }}
        >
          <CloseOutlined sx={{ fontSize: 20 }} />
        </IconButton>
      )}
      <Paper
        sx={{
          cursor: 'pointer',
          flex: 1,
          padding: 2,
          borderRadius: 4,
          backgroundColor: theme.palette.white[300],
          border: '3px solid transparent',
          borderColor: checked ? '#fa4242cf' : 'transparent'
        }}
        onClick={onClick}
      >
        <Box direction="row" display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="body2Medium" sx={{ fontSize: 16 }}>
              {email}
            </Typography>
          </Box>
          <Box justifyContent="center">
            <Image width={32} height={32} src={PayPalIconPNG} />
          </Box>
        </Box>
      </Paper>
    </Stack>
  )
}
