import axiosInstance from '@utils/axios'
import { get } from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'

const StaticAssetsContext = createContext({
  loading: false,
  files: {
    SELL_COVER: null,
    HOME_COVER: null
  }
})

export const useStaticAssets = () => {
  return useContext(StaticAssetsContext)
}

export function StaticAssetsProvider({ children }) {
  const [files, setFiles] = useState({})
  const [loading, setLoading] = useState()

  useEffect(() => {
    const fetchFiles = async () => {
      setLoading(true)

      try {
        const response = await axiosInstance.get(
          `/notion-database/${process.env.REACT_APP_NOTION_STATIC_DATA}`
        )

        if (response.status === 200) {
          const data = await response.data

          const staticFiles = {}
          data.results.forEach((result) => {
            if (!get(result, 'properties.Key.title[0].plain_text')) return

            staticFiles[get(result, 'properties.Key.title[0].plain_text')] = {
              src: get(result, 'properties.Image.files[0].file.url'),
              alt: get(result, 'properties.Alt.rich_text[0].plain_text'),
              redirect: get(result, 'properties.URL.url')
            }
          })

          setFiles(staticFiles)
        }
      } catch (err) {
      } finally {
        setLoading(false)
      }
    }

    fetchFiles()
  }, [])

  return (
    <StaticAssetsContext.Provider value={{ files, loading, setFiles }}>
      {children}
    </StaticAssetsContext.Provider>
  )
}
