import React from 'react'
import { Stack, Typography } from '@mui/material'
import Image from '@components/Image'
import { getPublicAssetUrl } from '@utils/common'

const ErrorText = ({ children, sx, wrapperSx, textAlign }) => (
  <Stack
    sx={{
      flexDirection: 'row',
      gap: '4px',
      alignItems: 'center',
      marginTop: '8px',
      justifyContent: textAlign,
      ...wrapperSx
    }}
  >
    <Image src={getPublicAssetUrl('/icons/error-sign.svg')} />
    <Typography
      variant="caption"
      color="error"
      component="p"
      sx={{
        fontSize: 12,
        lineHeight: 1.5,
        fontWeight: 300,
        ...sx
      }}
    >
      {children}
    </Typography>
  </Stack>
)

export default ErrorText
