import { styled, Paper } from '@mui/material'

export const AmountSelectorItem = styled(Paper)(
  ({ theme, isSelected, isDisabled }) => ({
    backgroundColor: isDisabled
      ? theme.palette.grey[300]
      : theme.palette.grey[100],
    ...theme.typography.body2,
    padding: theme.spacing(1),
    borderColor: isSelected ? 'red' : 'transparent',
    borderWidth: '1px',
    opacity: isDisabled ? 0.3 : 1,
    borderStyle: 'solid',
    borderRadius: 12,
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    color: theme.palette.text.secondary
  })
)
