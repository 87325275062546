import { lazy } from 'react'

const CategoryDetailPage = lazy(() =>
  import('@features/explore/pages/category-detail-page')
)
const ExploreCategoryPage = lazy(() =>
  import('@features/explore/pages/category-page')
)
const ExploreCreatorsPage = lazy(() =>
  import('@features/explore/pages/ExploreCreatorsPage')
)
const ExploreTagPage = lazy(() =>
  import('@features/explore/pages/ExploreTagPage')
)
const TagDetailPage = lazy(() =>
  import('@features/explore/pages/tag-detail-page')
)
const Help = lazy(() => import('@features/help'))
const AllListings = lazy(() => import('@features/explore/pages/listings'))
const Whitepaper = lazy(() => import('@features/whitepaper'))
const Releases = lazy(() => import('@features/releases'))
// import Whitepaper from '@features/whitepaper'
// import ProfilePage from '@features/profile'
// import Homepage from '@features/homepage'
const CreateCreatorProfile = lazy(() =>
  import('@features/creator-profile/create-profile')
)
const ConnectWallet = lazy(() => import('@features/connect-wallet'))
const CreateListing = lazy(() => import('@features/listing/create-listing'))
const AuthGuard = lazy(() => import('@routes/AuthGuard'))
const CreatorPageLayout = lazy(() => import('@features/creator'))
const CreatorIndex = lazy(() => import('@features/creator/CreatorIndex'))
const CreatorListingDetail = lazy(() =>
  import('@features/creator/CreatorListingDetail')
)
const EditCreatorProfile = lazy(() =>
  import('@features/creator-profile/edit-profile')
)
const EditListing = lazy(() => import('@features/listing/update-listing'))
const Activities = lazy(() => import('@features/profile/Activities'))
const Listings = lazy(() => import('@features/profile/Listings'))
const Assets = lazy(() => import('@features/profile/Assets'))
const Homepage = lazy(() => import('@features/homepage'))
const ExplorePage = lazy(() => import('@features/explore'))
// const FeedPage = lazy(() => import('@features/feed'))
const WalletPage = lazy(() => import('@features/wallet-profile'))
const WalletProfileIndex = lazy(() =>
  import('@features/wallet-profile/WalletProfileIndex')
)
const GokooHistory = lazy(() =>
  import('@features/wallet-profile/history/GokooHistory')
)
const MokooHistory = lazy(() =>
  import('@features/wallet-profile/history/MokooHistory')
)
// const NotificationsPage = lazy(() => import('@features/notifications'))
const SellPage = lazy(() => import('@features/sell'))
const NotFoundPage = lazy(() => import('@features/not-found'))
const TransactionConfirmationPage = lazy(() =>
  import('@features/transaction-confirmation')
)

export const ROUTE = {
  HOME: {
    TITLE: 'Home',
    PATH: '/'
  },
  NOT_FOUND: {
    TITLE: '404 Not Found',
    PATH: '/404'
  },
  EXPLORE: {
    TITLE: 'Explore',
    PATH: '/explore',
    CHILDREN: {
      EXPLORE_CATEGORY_DETAIL: {
        TITLE: 'Categories',
        PATH: '/explore/categories/:category'
      },
      EXPLORE_CATEGORIES: {
        TITLE: 'Categories',
        PATH: '/explore/categories'
      },
      ALL_LISTINGS: {
        TITLE: 'All Listings',
        PATH: '/explore/listings'
      },
      EXPLORE_TAG_DETAIL: {
        TITLE: 'Tags',
        PATH: '/explore/tags/:tag'
      },
      EXPLORE_TAGS: {
        TITLE: 'Tags',
        PATH: '/explore/tags'
      },
      EXPLORE_CREATORS: {
        TITLE: 'Creators',
        PATH: '/explore/creators'
      }
    }
  },
  SHOPS: {
    TITLE: 'Shops',
    PATH: '/shops'
  },
  FEED: {
    TITLE: 'Feed',
    PATH: '/feed'
  },
  TRANSACTION_CONFIRMATION: {
    TITLE: 'CONFIRMATION',
    PATH: '/confirmation/:type/:orderId'
  },
  WALLET: {
    TITLE: 'Wallet',
    PATH: '/wallet',
    CHILDREN: {
      GOKOO_HISTORY: {
        TITLE: 'GOKOO Transaction History',
        PATH: '/wallet/history/gokoo'
      },
      MOKOO_HISTORY: {
        TITLE: 'MOKOO Transaction History',
        PATH: '/wallet/history/mokoo'
      }
    }
  },
  // NOTIFICATIONS: {
  //   TITLE: 'Notifications',
  //   PATH: '/notifications'
  // },
  CONNECT_WALLET: {
    TITLE: 'Connect Wallet',
    PATH: '/connect'
  },
  PROMOTE: {
    TITLE: '',
    PATH: process.env.REACT_APP_PROMOTE_PATH || '/connect'
  },
  WHITEPAPER: {
    TITLE: 'Whitepaper',
    PATH: '/whitepaper',
    CHILDREN: {
      WHITEPAPER_SUBPAGE: {
        TITLE: 'Whitepaper',
        PATH: '/whitepaper/:pageId'
      },
      WHITEPAPER_INDEX: {
        TITLE: 'Whitepaper',
        PATH: '/whitepaper'
      }
    }
  },
  HELP: {
    TITLE: 'Help',
    PATH: '/help',
    CHILDREN: {
      HELP_SUBPAGE: {
        TITLE: 'Help',
        PATH: '/help/:pageId'
      },
      HELP_INDEX: {
        TITLE: 'Help',
        PATH: '/help'
      }
    }
  },
  RELEASES: {
    TITLE: 'Releases',
    PATH: '/releases'
  },
  CREATOR: {
    TITLE: 'Profile',
    PATH: '/sell/profile',
    CHILDREN: {
      CREATE_PROFILE: {
        TITLE: 'Register',
        PATH: '/sell/profile/register'
      },
      EDIT_PROFILE: {
        TITLE: 'Edit Profile',
        PATH: '/sell/profile/edit'
      }
    }
  },
  LISTING: {
    TITLE: 'Listing',
    PATH: '/sell/listing',
    CHILDREN: {
      CREATE_LISTING: {
        TITLE: 'Create a new listing',
        PATH: '/sell/listing/create'
      },
      EDIT_LISTING: {
        TITLE: 'Edit listing',
        PATH: '/sell/listing/edit/:listingId'
      }
    }
  },
  MY_PROFILE: {
    TITLE: 'Sell',
    PATH: '/sell',
    CHILDREN: {
      EARNINGS: {
        TITLE: 'My Earnings',
        PATH: '/sell/earnings'
      },
      LISTINGS: {
        TITLE: 'My Earnings',
        PATH: '/sell/listings'
      },
      ASSETS: {
        TITLE: 'My Storage',
        PATH: '/sell/storage'
      }
    }
  },
  CREATOR_SITE: {
    TITLE: 'Creator Shop',
    PATH: '/shop/:storeDomain',
    CHILDREN: {
      INDEX: {
        TITLE: 'Creator index',
        PATH: '/shop/:storeDomain'
      },
      LISTING: {
        TITLE: 'Listing',
        PATH: '/shop/:storeDomain/:slug'
      }
    }
  }
}

export const routes = [
  {
    path: ROUTE.CONNECT_WALLET.PATH,
    title: ROUTE.CONNECT_WALLET.TITLE,
    element: <ConnectWallet />
  },
  {
    path: ROUTE.WHITEPAPER.PATH,
    title: ROUTE.WHITEPAPER.TITLE,
    children: [
      {
        path: ROUTE.WHITEPAPER.CHILDREN.WHITEPAPER_SUBPAGE.PATH,
        title: ROUTE.WHITEPAPER.CHILDREN.WHITEPAPER_SUBPAGE.TITLE,
        element: <Whitepaper />
      },
      {
        path: ROUTE.WHITEPAPER.CHILDREN.WHITEPAPER_INDEX.PATH,
        title: ROUTE.WHITEPAPER.CHILDREN.WHITEPAPER_INDEX.TITLE,
        element: <Whitepaper />
      }
    ]
  },
  {
    path: ROUTE.HELP.PATH,
    title: ROUTE.HELP.TITLE,
    children: [
      {
        path: ROUTE.HELP.CHILDREN.HELP_SUBPAGE.PATH,
        title: ROUTE.HELP.CHILDREN.HELP_SUBPAGE.TITLE,
        element: <Help />
      },
      {
        path: ROUTE.HELP.CHILDREN.HELP_INDEX.PATH,
        title: ROUTE.HELP.CHILDREN.HELP_INDEX.TITLE,
        element: <Help />
      }
    ]
  },
  {
    path: ROUTE.RELEASES.PATH,
    title: ROUTE.RELEASES.TITLE,
    element: <Releases />
  },
  {
    path: ROUTE.CREATOR.PATH,
    title: ROUTE.CREATOR.TITLE,
    children: [
      {
        path: ROUTE.CREATOR.CHILDREN.CREATE_PROFILE.PATH,
        title: ROUTE.CREATOR.CHILDREN.CREATE_PROFILE.TITLE,
        element: (
          <AuthGuard
            redirect={ROUTE.CREATOR.CHILDREN.CREATE_PROFILE.PATH}
            component={CreateCreatorProfile}
          />
        )
      },
      {
        path: ROUTE.CREATOR.CHILDREN.EDIT_PROFILE.PATH,
        title: ROUTE.CREATOR.CHILDREN.EDIT_PROFILE.TITLE,
        element: (
          <AuthGuard
            redirect={ROUTE.CREATOR.CHILDREN.EDIT_PROFILE.PATH}
            component={EditCreatorProfile}
          />
        )
      }
    ]
  },
  {
    path: ROUTE.LISTING.PATH,
    title: ROUTE.LISTING.TITLE,
    children: [
      {
        path: ROUTE.LISTING.CHILDREN.CREATE_LISTING.PATH,
        title: ROUTE.LISTING.CHILDREN.CREATE_LISTING.TITLE,
        element: (
          <AuthGuard
            redirect={ROUTE.CREATOR.CHILDREN.CREATE_PROFILE.PATH}
            component={CreateListing}
          />
        )
      },
      {
        path: ROUTE.LISTING.CHILDREN.EDIT_LISTING.PATH,
        title: ROUTE.LISTING.CHILDREN.EDIT_LISTING.TITLE,
        element: (
          <AuthGuard
            redirect={ROUTE.CREATOR.CHILDREN.CREATE_PROFILE.PATH}
            component={EditListing}
          />
        )
      }
    ]
  },
  {
    path: ROUTE.MY_PROFILE.CHILDREN.EARNINGS.PATH,
    title: ROUTE.MY_PROFILE.CHILDREN.EARNINGS.TITLE,
    element: (
      <AuthGuard
        // redirect={ROUTE.CREATOR.CHILDREN.CREATE_PROFILE.PATH}
        redirect={ROUTE.MY_PROFILE.PATH}
        component={Activities}
      />
    )
  },
  {
    path: ROUTE.MY_PROFILE.CHILDREN.LISTINGS.PATH,
    title: ROUTE.MY_PROFILE.CHILDREN.LISTINGS.TITLE,
    element: (
      <AuthGuard
        // redirect={ROUTE.CREATOR.CHILDREN.CREATE_PROFILE.PATH}
        redirect={ROUTE.MY_PROFILE.PATH}
        component={Listings}
      />
    )
  },
  {
    path: ROUTE.TRANSACTION_CONFIRMATION.PATH,
    title: ROUTE.TRANSACTION_CONFIRMATION.TITLE,
    element: (
      <AuthGuard
        redirect={ROUTE.MY_PROFILE.PATH}
        component={TransactionConfirmationPage}
      />
    )
  },
  {
    path: ROUTE.MY_PROFILE.CHILDREN.ASSETS.PATH,
    title: ROUTE.MY_PROFILE.CHILDREN.ASSETS.TITLE,
    element: (
      <AuthGuard
        // redirect={ROUTE.CREATOR.CHILDREN.CREATE_PROFILE.PATH}
        redirect={ROUTE.MY_PROFILE.PATH}
        component={Assets}
      />
    )
  },
  {
    path: ROUTE.CREATOR_SITE.PATH,
    title: ROUTE.CREATOR_SITE.TITLE,
    element: <CreatorPageLayout />,
    children: [
      {
        path: ROUTE.CREATOR_SITE.CHILDREN.LISTING.PATH,
        title: ROUTE.CREATOR_SITE.CHILDREN.LISTING.TITLE,
        element: <CreatorListingDetail />
      },
      {
        path: ROUTE.CREATOR_SITE.CHILDREN.INDEX.PATH,
        title: ROUTE.CREATOR_SITE.CHILDREN.INDEX.TITLE,
        element: <CreatorIndex />
      }
    ]
  },
  {
    path: ROUTE.EXPLORE.PATH,
    title: ROUTE.EXPLORE.TITLE,
    children: [
      {
        path: ROUTE.EXPLORE.CHILDREN.EXPLORE_CATEGORY_DETAIL.PATH,
        title: ROUTE.EXPLORE.CHILDREN.EXPLORE_CATEGORY_DETAIL.TITLE,
        element: <CategoryDetailPage />
      },
      {
        path: ROUTE.EXPLORE.CHILDREN.EXPLORE_CATEGORIES.PATH,
        title: ROUTE.EXPLORE.CHILDREN.EXPLORE_CATEGORIES.TITLE,
        element: <ExploreCategoryPage />
      },
      {
        path: ROUTE.EXPLORE.CHILDREN.ALL_LISTINGS.PATH,
        title: ROUTE.EXPLORE.CHILDREN.ALL_LISTINGS.TITLE,
        element: <AllListings />
      },
      {
        path: ROUTE.EXPLORE.CHILDREN.EXPLORE_TAG_DETAIL.PATH,
        title: ROUTE.EXPLORE.CHILDREN.EXPLORE_TAG_DETAIL.TITLE,
        element: <TagDetailPage />
      },
      {
        path: ROUTE.EXPLORE.CHILDREN.EXPLORE_TAGS.PATH,
        title: ROUTE.EXPLORE.CHILDREN.EXPLORE_TAGS.TITLE,
        element: <ExploreTagPage />
      },
      {
        path: ROUTE.EXPLORE.CHILDREN.EXPLORE_CREATORS.PATH,
        title: ROUTE.EXPLORE.CHILDREN.EXPLORE_CREATORS.TITLE,
        element: <ExploreCreatorsPage />
      },
      {
        path: ROUTE.EXPLORE.PATH,
        title: ROUTE.EXPLORE.TITLE,
        element: <ExplorePage />
      }
    ]
  },
  // {
  //   path: ROUTE.SHOPS.PATH,
  //   title: ROUTE.SHOPS.TITLE,
  //   element: <Homepage />
  // },
  // {
  //   path: ROUTE.FEED.PATH,
  //   title: ROUTE.FEED.TITLE,
  //   element: <FeedPage />
  // },
  {
    path: ROUTE.WALLET.PATH,
    title: ROUTE.WALLET.TITLE,
    element: <WalletPage />,
    children: [
      {
        path: ROUTE.WALLET.PATH,
        title: ROUTE.WALLET.TITLE,
        element: <WalletProfileIndex />
      },
      {
        path: ROUTE.WALLET.CHILDREN.GOKOO_HISTORY.PATH,
        title: ROUTE.WALLET.CHILDREN.GOKOO_HISTORY.TTILE,
        element: <GokooHistory />
      },
      {
        path: ROUTE.WALLET.CHILDREN.MOKOO_HISTORY.PATH,
        title: ROUTE.WALLET.CHILDREN.MOKOO_HISTORY.TITLE,
        element: <MokooHistory />
      }
    ]
  },
  // {
  //   path: ROUTE.NOTIFICATIONS.PATH,
  //   title: ROUTE.NOTIFICATIONS.TITLE,
  //   element: <NotificationsPage />
  // },
  {
    path: ROUTE.MY_PROFILE.PATH,
    title: ROUTE.MY_PROFILE.TITLE,
    element: <SellPage />
  },
  {
    path: ROUTE.NOT_FOUND.PATH,
    title: ROUTE.NOT_FOUND.TITLE,
    element: <NotFoundPage />
  },
  {
    path: ROUTE.HOME.PATH,
    title: ROUTE.HOME.TITLE,
    element: <Homepage />,
    index: true
  }
]

export const childrenPath = (() => {
  const routeKeys = []

  const getChildrenKeys = (routes, level = 1) => {
    routes.forEach((route) => {
      if (level > 1) {
        routeKeys.push({ path: route.path })
      }
      if (Array.isArray(route.children) && route.children.length > 0) {
        const childRoutes = route.children.filter(
          (childRoute) => childRoute.path !== route.path
        )
        return getChildrenKeys(childRoutes, level + 1)
      }
    })
  }

  getChildrenKeys(routes)
  return routeKeys
})()
