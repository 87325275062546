import React from 'react'
import { Backdrop as MuiBackdrop, Box, Typography, useTheme, CircularProgress } from '@mui/material'

const Backdrop = ({ open, loading, message, sx, progressTitles }) => {
  const theme = useTheme()

  return (
    <MuiBackdrop sx={{ zIndex: 9999, ...sx }} open={open}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '8px',
          backgroundColor: theme.palette.background.default,
          padding: {
            xs: '16px',
            sm: '24px',
            md: '32px'
          },
          borderRadius: '12px'
        }}
      >
        {loading && (
          <React.Fragment>
            <CircularProgress color="primary" />
            {progressTitles && (
              <Typography
                variant="body1Medium"
                sx={{
                  fontSize: {
                    xs: 14,
                    sm: 20
                  },
                  userSelect: 'none'
                }}
              >
                {progressTitles}
              </Typography>
            )}
          </React.Fragment>
        )}
        {message && (
          message && (
            <Typography
              variant="body1Medium"
              sx={{
                fontSize: {
                  xs: 14,
                  sm: 20
                },
                userSelect: 'none'
              }}
            >
              {message}
            </Typography>
          )
        )}
      </Box>
    </MuiBackdrop>
  )
}

export default Backdrop
