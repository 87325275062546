import { GOKOO_TOKEN } from '@common/constants/common'
import {
  OrderTypes,
  gen7PayLink,
  generateNotifyUrl,
  saveOrderInfoLocalStorage,
  supportedPaymentMethod
} from '@utils/payment.helper'
import BigNumber from 'bignumber.js'
import { v4 as uuidv4 } from 'uuid'

export const generateAlipayRequest = ({
  subtotal,
  exchangeRate,
  wallet,
  goBackUrl,
  depositAmount
}) => {
  // need to upgrade get live exchangerate later
  let convertedAmount = null
  if (subtotal !== null) {
    const numericSubtotal = Number(subtotal)
    convertedAmount = numericSubtotal * exchangeRate
    convertedAmount = Math.round(convertedAmount * 100) / 100
  }

  const orderId = uuidv4()

  saveOrderInfoLocalStorage(orderId, OrderTypes.deposit, {
    depositAmount,
    paymentMethod: supportedPaymentMethod.alipay,
    goBackUrl
  })

  const depositAmountInGokooDecimal = new BigNumber(depositAmount || 0)
    .multipliedBy(10 ** GOKOO_TOKEN.decimals)
    .toString()

  const notifyUrl = generateNotifyUrl({
    no: orderId,
    wallet,
    amount: depositAmountInGokooDecimal
  })

  const targetReturnUrl = `${process.env.REACT_APP_APP_URL}/confirmation/deposit/${orderId}`

  return {
    redirectUrl: gen7PayLink(
      orderId,
      convertedAmount,
      depositAmount,
      notifyUrl,
      targetReturnUrl
    ),
    orderId
  }
}
