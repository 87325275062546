import { Contract, providers } from 'ethers'
import { Interface } from 'ethers/lib/utils'
import { ENV } from '@common/constants/common'

// account ABI
import accountMainnetAbi from '@common/contracts/abi/mainnet/account.json'
import accountTestnetAbi from '@common/contracts/abi/testnet/account.json'

// license ABI
import licenseMainnetAbi from '@common/contracts/abi/mainnet/license.json'
import licenseTestnetAbi from '@common/contracts/abi/testnet/license.json'

// transaction ABI
import transactionMainnetAbi from '@common/contracts/abi/mainnet/transaction.json'
import transactionTestnetAbi from '@common/contracts/abi/testnet/transaction.json'

// transaction ABI
import ERC1155Abi from '@common/contracts/abi/mainnet/1155.json'

const provider = new providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL)

export const ACCOUNT_ADDRESS = process.env.REACT_APP_ACCOUNT_ADDRESS
export const ART_LICENSE_NFT_ADDRESS =
  process.env.REACT_APP_ART_LICENSE_NFT_ADDRESS
export const ART_LISTING_NFT_ADDRESS =
  process.env.REACT_APP_ART_LISTING_NFT_ADDRESS
export const TRANSACTION_ADDRESS = process.env.REACT_APP_TRANSACTION_ADDRESS
export const USDC_ADDRESS = process.env.REACT_APP_USDC_ADDRESS

export const accountAbi =
  process.env.REACT_APP_ENV === ENV.PROD ? accountMainnetAbi : accountTestnetAbi
export const transactionAbi =
  process.env.REACT_APP_ENV === ENV.PROD
    ? transactionMainnetAbi
    : transactionTestnetAbi
export const licenseAbi =
  process.env.REACT_APP_ENV === ENV.PROD ? licenseMainnetAbi : licenseTestnetAbi

export const erc1155Abi = ERC1155Abi

export const ACCOUNT_INTERFACE = new Interface(accountAbi)
export const ACCOUNT_CONTRACT = new Contract(
  process.env.REACT_APP_ACCOUNT_ADDRESS,
  ACCOUNT_INTERFACE,
  provider
)
export const USDC_CONTRACT_ADDRESS_ON_ETHEREUM =
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'

export const ACCOUNT_FUNCTION = {
  IS_REGISTERED: 'isRegistered',
  UPDATE_CREATOR: 'updateCreator'
}

export const TRANSACTION_FUNCTION = {
  CREATE_LISTING: 'createListing',
  EDIT_LISTING: 'updateListing',
  LICENSE: 'license'
}
