import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
  Alert
} from '@mui/material'
import React, { useMemo } from 'react'
import { Box, Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { GKOOIcon } from '@components/icons/GKOOIcon'
import { preventInputNonDecimal } from '@utils/common'
// import { AmountSelectorItem } from '../../../AmountSelectorItem'
import { useWalletContext } from '@features/wallet/WalletContext'
import { useGOKOOBalance } from '@common/hooks/useBalance'
import GOKOOBalance from '../../../GOKOOBalance'
import { GOKOO_TOKEN } from '@common/constants/common'
import { formatUnits } from 'viem'

// const withdrawAmountSuggestions = [
//   1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000
// ]
export default function EnterAmount({ onNext, amount, setAmount }) {
  const { account } = useWalletContext()
  const { data: gokooBalanceData } = useGOKOOBalance(account)

  const currentGokooBalance = useMemo(() => {
    if (!gokooBalanceData) return 0

    return formatUnits(gokooBalanceData, GOKOO_TOKEN.decimals)
  }, [gokooBalanceData])

  return (
    <>
      <Stack height="100%" justifyContent="space-between">
        <Box>
          <Stack justifyContent="flex-start" alignItems="flex-start" gap={1}>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                '.MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  paddingRight: '0'
                }
              }}
            >
              <OutlinedInput
                value={amount}
                type="number"
                placeholder="Enter amount"
                onChange={(event) => {
                  setAmount(parseInt(event.target.value))
                }}
                onKeyDown={preventInputNonDecimal}
                startAdornment={
                  <InputAdornment position="start">
                    <GKOOIcon size={20} />
                  </InputAdornment>
                }
                endAdornment={
                  <Button
                    onClick={() => {
                      setAmount(parseInt(currentGokooBalance))
                    }}
                    sx={{ borderRadius: 4, minWidth: 50, padding: 0 }}
                  >
                    MAX
                  </Button>
                }
              />
            </FormControl>
            <Box px={1}>
              <GOKOOBalance balance={currentGokooBalance} />
            </Box>
          </Stack>
        </Box>
        <Box>
          <Alert severity="info" sx={{ borderRadius: 2, marginBottom: 2 }}>
            The minimum deposit and withdrawal amount is 100{' '}
            {GOKOO_TOKEN.symbol}, equivalent to $1.00.
          </Alert>
          <Button
            variant="contained"
            color="success"
            size="large"
            onClick={(event) => {
              event.stopPropagation()
              onNext()
            }}
            sx={{
              lineHeight: 1.4,
              minWidth: '100%',
              backgroundColor: 'success.main',
              ':hover': {
                backgroundColor: 'success.dark'
              },
              cursor: 'pointer'
            }}
            disabled={
              !amount ||
              amount > +currentGokooBalance ||
              amount < 1 / GOKOO_TOKEN.rate
            }
          >
            <Box
              component="span"
              sx={{
                marginRight: 1,
                fontSize: 20
              }}
            >
              Continue
            </Box>
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </Box>
      </Stack>
    </>
  )
}
