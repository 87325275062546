import {
  Alert,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack
} from '@mui/material'
import React, { useMemo } from 'react'
import { Box, Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { commify } from 'ethers/lib/utils'
import { GOKOO_TOKEN } from '@common/constants/common'
import GKOORate from '@components/GKOORate'
import { GKOOIcon } from '@components/icons/GKOOIcon'
import { preventInputNonDecimal } from '@utils/common'
import { AmountSelectorItem } from '../../../AmountSelectorItem'
import { useWalletContext } from '@features/wallet/WalletContext'
import { useGOKOOBalance } from '@common/hooks/useBalance'
import GOKOOBalance from '../../../GOKOOBalance'
import { formatUnits } from 'viem'

const priceList = [100, 250, 500, 1000, 1250, 1500, 2000, 2500, 5000]

export default function EnterAmount({ onNext, amount, setAmount }) {
  const { account } = useWalletContext()
  const { data: gokooBalanceData } = useGOKOOBalance(account)

  const currentGokooBalance = useMemo(() => {
    if (!gokooBalanceData) return 0

    return formatUnits(gokooBalanceData, GOKOO_TOKEN.decimals)
  }, [gokooBalanceData])

  return (
    <>
      <Stack height="100%" justifyContent="space-between">
        <Box>
          <Stack justifyContent="flex-start" alignItems="flex-start" gap={1}>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                '.MuiOutlinedInput-root': {
                  borderRadius: '12px'
                }
              }}
            >
              <OutlinedInput
                value={amount}
                type="number"
                placeholder="Enter amount"
                onChange={(event) => {
                  setAmount(parseInt(event.target.value))
                }}
                onKeyDown={preventInputNonDecimal}
                startAdornment={
                  <InputAdornment position="start">
                    <GKOOIcon size={20} />
                  </InputAdornment>
                }
              />
            </FormControl>

            <Stack
              width="100%"
              px={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <GOKOOBalance balance={currentGokooBalance} />
              <Box mt={0.5}>
                <GKOORate />
              </Box>
            </Stack>
          </Stack>

          <Grid
            container
            mt={2}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 3, md: 3 }}
            columns={{ xs: 3, sm: 3, md: 3 }}
          >
            {priceList.map((price) => (
              <Grid key={price} item xs={1}>
                <AmountSelectorItem
                  isSelected={amount === price}
                  onClick={() => {
                    setAmount(price)
                  }}
                >
                  {commify(price)}
                </AmountSelectorItem>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box mt={2}>
          <Alert severity="info" sx={{ borderRadius: 2, marginBottom: 2 }}>
            The minimum deposit and withdrawal amount is 100{' '}
            {GOKOO_TOKEN.symbol}, equivalent to $1.00.
          </Alert>
          <Button
            variant="contained"
            color="success"
            size="large"
            onClick={(event) => {
              event.stopPropagation()
              onNext()
            }}
            sx={{
              lineHeight: 1.4,
              minWidth: '100%',
              backgroundColor: 'success.main',
              ':hover': {
                backgroundColor: 'success.dark'
              },
              cursor: 'pointer'
            }}
            disabled={!amount || amount < 100}
          >
            <Box
              component="span"
              sx={{
                marginRight: 1,
                fontSize: 20
              }}
            >
              Continue
            </Box>
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </Box>
      </Stack>
    </>
  )
}
