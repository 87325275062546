import React from 'react'

const CheckedCheckboxIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect width="22" height="22" x="1" y="1" fill="#FA4242" rx="8" />
    <path
      fill="#fff"
      d="M10.189 17.174c.436 0 .77-.171 1.003-.514l5.91-9.045c.08-.127.14-.25.177-.368.038-.119.057-.233.057-.343 0-.296-.099-.542-.298-.737a1.036 1.036 0 00-.755-.292c-.208 0-.383.043-.527.127-.14.08-.275.22-.406.42l-5.186 8.194-2.641-3.269a1.045 1.045 0 00-.844-.412c-.305 0-.559.099-.762.298a.995.995 0 00-.298.743c0 .13.023.258.07.38.046.123.126.25.24.381l3.276 3.968c.262.313.59.47.984.47z"
    />
  </svg>
)

export default CheckedCheckboxIcon
