import { encodeFunctionData } from 'viem'
import { currentChain, publicClient } from '../wagmi-config'
import { ForwarderAbi } from './forwarder-abi'

const FORWARDER_CONTRACT = process.env.REACT_APP_FORWARDER_CONTRACT

const domain = {
  name: 'GSNv2 Forwarder',
  version: '0.0.1',
  chainId: currentChain.id,
  verifyingContract: FORWARDER_CONTRACT
}

const types = {
  ForwardRequest: [
    { name: 'from', type: 'address' },
    { name: 'to', type: 'address' },
    { name: 'value', type: 'uint256' },
    { name: 'gas', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'data', type: 'bytes' }
  ],
  Execute: [
    { name: 'req', type: 'ForwardRequest' },
    { name: 'signature', type: 'bytes' }
  ]
}

export const sendMetaTransaction = async ({
  from,
  to,
  abi,
  functionName,
  args,
  walletClient
}) => {
  const calldata = encodeFunctionData({
    abi,
    functionName,
    args
  })

  const request = {
    to,
    from,
    data: calldata,
    gas: '1000000',
    value: '0'
  }

  console.log('request', request)

  const nonce = await publicClient.readContract({
    abi: ForwarderAbi,
    address: FORWARDER_CONTRACT,
    functionName: 'getNonce',
    args: [request.from]
  })

  request.nonce = Number(nonce)

  const signature = await walletClient?.signTypedData({
    account: from,
    primaryType: 'ForwardRequest',
    domain,
    types,
    message: request
  })

  const isValid = await publicClient.readContract({
    abi: ForwarderAbi,
    address: FORWARDER_CONTRACT,
    functionName: 'verify',
    args: [request, signature]
  })

  if (!isValid) {
    return null
  }

  const response = await fetch(process.env.REACT_APP_OPENZEPPELIN_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      request,
      signature,
      forwarderAddress: FORWARDER_CONTRACT,
      type: 'forward'
    })
  })

  const txResponse = await response.json()

  console.log('response', txResponse)

  if (txResponse.status === 'success') {
    return JSON.parse(txResponse.result)
  }
  return null
}
