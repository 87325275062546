// noinspection JSValidateTypes
import { get, isEmpty } from 'lodash'
import { showToast } from '@utils/toast'
import { GOKOO_TOKEN, TOAST_TYPE } from '@common/constants/common'
import { utils, BigNumber } from 'ethers'
import { filesize } from 'filesize'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import licenseMap from '@features/listing/license.map'

export const scrollToId = (id) => {
  const targetTopPos = document.getElementById(id).getBoundingClientRect().top
  const offsetPos = targetTopPos + window.scrollY - 90

  window.scrollTo({
    top: offsetPos
  })
}

export const isValidEmail = (email) =>
  !isEmpty(email) &&
  // RFC 2822 standard email validation
  // eslint-disable-next-line no-control-regex
  /(?:[a-z\d!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z\d!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z\d](?:[a-z\d-]*[a-z\d])?\.)+[a-z\d](?:[a-z\d-]*[a-z\d])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?|[a-z\d-]*[a-z\d]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/.test(
    email.toLowerCase()
  )

// maybe write a hook ?

export const getImageDimensions = (url, setDimensions) => {
  const img = new Image()
  img.src = url

  setDimensions != null &&
    (img.onload = () => {
      setDimensions({
        height: img.height,
        width: img.width
      })
    })
}

export const isNetworkMismatch = (networkMismatch) => {
  if (networkMismatch) {
    showToast('Please switch to Polygon network to continue.', TOAST_TYPE.ERROR)

    return true
  }

  return false
}

export const preventInputNonInteger = (event) =>
  ['e', 'E', '+', '-', '.', 'ArrowDown', 'ArrowUp', ' '].includes(event.key) &&
  event.preventDefault()

export const preventInputNonDecimal = (event) =>
  ['e', 'E', '+', '-', 'ArrowDown', 'ArrowUp', ' '].includes(event.key) &&
  event.preventDefault()

export const copyToClipboard = async (content, name) => {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(content)
  } else {
    document.execCommand('copy', false, content)
  }

  showToast(`Copied ${name}!`, TOAST_TYPE.INFO)
}

export const withTrailingDecimals = (
  rawInput,
  decimals = 2,
  trailingChar = '0'
) => {
  if (!rawInput) {
    return undefined
  }

  let input

  if (typeof rawInput === 'number') {
    input = rawInput.toString()
  } else if (typeof rawInput === 'string') {
    input = rawInput
  } else {
    return undefined
  }

  let removedLeadingZerosIntPart = input.replace(/^0+/, '')

  if (removedLeadingZerosIntPart.startsWith('.')) {
    removedLeadingZerosIntPart = `0${removedLeadingZerosIntPart}`
  }

  const [intPart, decimalsPart] = removedLeadingZerosIntPart.split('.')

  if (decimalsPart) {
    return `${intPart}.${decimalsPart.padEnd(decimals, trailingChar)}`
  } else {
    return `${intPart}.${'0'.padEnd(decimals, trailingChar)}`
  }
}

export const parsePricingDecimals = (
  value,
  decimals = GOKOO_TOKEN.decimals
) => {
  return parseUnits((value || 0).toString(), decimals).toString()
}
export const formatPricingHumanNumber = (
  value,
  decimals = GOKOO_TOKEN.decimals
) => {
  return formatUnits(BigNumber.from((value || 0).toString()), decimals)
}

export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export const toCent = (amount) => {
  const str = amount.toString()
  const [int] = str.split('.')

  return Number(
    parseFloat(amount)
      .toFixed(2)
      .replace('.', '')
      .padEnd(int.length === 1 ? 3 : 4, '0')
  )
}

export const shortenIfAddress = (address, leftPad = 6, rightPad = 4) => {
  if (typeof address === 'string' && address.length > 0) {
    const etherAddress = utils.getAddress(address)

    return (
      etherAddress.substring(0, leftPad) +
      '...' +
      etherAddress.substring(etherAddress.length - rightPad)
    )
  }

  return ''
}
export const shortenTxHash = (txHash, leftPad = 6, rightPad = 4) => {
  if (!txHash) return ''

  return `${txHash.slice(0, leftPad)}...${txHash.slice(-rightPad)}`
}

export const getFilesSize = (files) => {
  if (!Array.isArray(files)) {
    throw new Error('Input is not an array')
  }

  const totalSize = files.reduce(
    (pre, curr) => pre.size ?? 0 + curr.size ?? 0,
    0
  )

  return !isEmpty(files) ? filesize(totalSize) : 0
}

export const getFileExtension = (file) => file?.name?.split('.')?.pop()

export const isDecimalExceedsMaxLength = (value, max) => {
  if (!value || typeof value !== 'string') {
    throw new Error('Value must be a string')
  }

  if (!max) {
    throw new Error('Invalid max value')
  }

  const decimalPart = value.split('.')[1]

  if (!decimalPart) {
    return false
  }

  return decimalPart.length > max
}

export const getIpfsClient = async () => {
  const { create } = await import('ipfs-http-client')

  const client = await create({
    url: process.env.REACT_APP_IPFS_CLIENT_BASE
  })

  return client
}

export const getNFTTokenIdFromReceipt = (receipt) => {
  return BigNumber.from(receipt.logs[0].topics[3]).toNumber()
}

export const getNFTTokenIdFromReceiptData = (receipt) => {
  const log =
    (get(receipt, 'logs[0].data', '') &&
      get(receipt, 'logs[0].data', '').slice(0, 66)) ||
    null
  if (!log) return null
  return BigNumber.from(log).toNumber()
}

/**
 *
 * @param {Array<File>} files Array of files
 * @returns {number} Total files size
 */
export const getTotalFileSize = (files) => {
  return (
    files?.reduce((accumulator, file) => {
      const currSize = Number(file.size)

      if (Number.isNaN(currSize)) {
        return accumulator
      }

      return accumulator + currSize
    }, 0) ?? 0
  )
}

export const getLicensesByListing = (listing) => {
  const foundLicense = licenseMap.find((license) => {
    const {
      includeProperAttribution,
      allowCommercialUses,
      allowMod,
      allowShareAdaptations
    } = license.condition
    return (
      listing.includeProperAttribution === includeProperAttribution &&
      listing.allowCommercialUses === allowCommercialUses &&
      listing.allowMod === allowMod &&
      listing.allowShareAdaptations === allowShareAdaptations
    )
  })

  if (
    listing.creativeLicense.length > 0 &&
    listing.creativeLicense[0].isCustom
  ) {
    const customLicense = listing.creativeLicense[0]

    return {
      name: customLicense.name,
      icons: foundLicense ? foundLicense.license.icons : []
    }
  }

  if (!foundLicense) {
    return null
  }

  return foundLicense.license
}

export const getPublicAssetUrl = (path) => {
  let combinedPath
  if (path.startsWith('/')) {
    combinedPath = `${process.env.REACT_APP_PUBLIC_STORAGE_BUCKET}${path}`
  } else {
    combinedPath = `${process.env.REACT_APP_PUBLIC_STORAGE_BUCKET}/${path}`
  }

  return new URL(combinedPath, 'https://storage.googleapis.com').toString()
}

export const trimGuid = (input) => input?.substring(0, 36)
