import React, { useMemo, useContext } from 'react'
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material'
import { getDesignTokens } from './theme'

const AppThemeContext = React.createContext({
  themeMode: undefined
})

const AppTheme = ({ children }) => {
  const mode = useMemo(() => {
    return 'light'
  }, [])

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode])
  return (
    <AppThemeContext.Provider
      value={{
        themeMode: mode
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </AppThemeContext.Provider>
  )
}

/**
 * Use app theme context
 * @returns { themeMode: 'light' | 'dark' | undefined }
 */
export const useAppTheme = () => {
  const context = useContext(AppThemeContext)
  if (!context) {
    throw new Error('useAppTheme must be inside a AppThemeProvider')
  }
  return context
}

export default AppTheme
