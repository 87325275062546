import { ACCOUNT_CONTRACT } from '@common/contracts'
import { useQuery } from '@tanstack/react-query'

export const useRegistered = (address) => {
  return useQuery({
    queryKey: ['registered', address],
    queryFn: async () => {
      if (address) {
        const isRegistered = await ACCOUNT_CONTRACT.isRegistered(address)

        return !!isRegistered
      }

      return false
    }
  })
}
