import {
  faCreativeCommons,
  faCreativeCommonsBy,
  faCreativeCommonsNc,
  faCreativeCommonsNd,
  faCreativeCommonsSa,
  faCreativeCommonsZero
} from '@fortawesome/free-brands-svg-icons'
import { LISTING_LICENSE } from '@common/constants/common'

export default [
  {
    condition: {
      includeProperAttribution: true,
      allowCommercialUses: true,
      allowMod: true,
      allowShareAdaptations: true
    },
    license: {
      name: LISTING_LICENSE.CC_BY_40,
      icons: [faCreativeCommons, faCreativeCommonsBy],
      description:
        'This license requires that reusers give credit to the creator. It allows reusers to distribute, remix, adapt, and build upon the material in any medium or format, even for commercial purposes.',
      learnMoreLabel: 'See the CC BY 4.0 Deed',
      learnMoreLink: 'https://creativecommons.org/licenses/by/4.0/'
    }
  },
  {
    condition: {
      includeProperAttribution: true,
      allowCommercialUses: true,
      allowMod: true,
      allowShareAdaptations: false
    },
    license: {
      name: LISTING_LICENSE.CC_BY_SA_40,
      icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsSa],
      description:
        'This license requires that reusers give credit to the creator. It allows reusers to distribute, remix, adapt, and build upon the material in any medium or format, even for commercial purposes. If others remix, adapt, or build upon the material, they must license the modified material under identical terms.',
      learnMoreLabel: 'See the CC BY-SA 4.0 Deed',
      learnMoreLink: 'https://creativecommons.org/licenses/by-sa/4.0/'
    }
  },
  {
    condition: {
      includeProperAttribution: true,
      allowCommercialUses: true,
      allowMod: false,
      allowShareAdaptations: false
    },
    license: {
      name: LISTING_LICENSE.CC_BY_ND_40,
      icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNd],
      description:
        'This license requires that reusers give credit to the creator. It allows reusers to copy and distribute the material in any medium or format in unadapted form only, even for commercial purposes.',
      learnMoreLabel: 'See the CC BY-ND 4.0 Deed',
      learnMoreLink: 'https://creativecommons.org/licenses/by-nd/4.0/'
    }
  },
  {
    condition: {
      includeProperAttribution: true,
      allowCommercialUses: false,
      allowMod: true,
      allowShareAdaptations: true
    },
    license: {
      name: LISTING_LICENSE.CC_BY_NC_40,
      icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc],
      description:
        'This license requires that reusers give credit to the creator. It allows reusers to distribute, remix, adapt, and build upon the material in any medium or format, for noncommercial purposes only.',
      learnMoreLabel: 'See the CC BY-NC 4.0 Deed',
      learnMoreLink: 'https://creativecommons.org/licenses/by-nc/4.0/'
    }
  },
  {
    condition: {
      includeProperAttribution: true,
      allowCommercialUses: false,
      allowMod: true,
      allowShareAdaptations: false
    },
    license: {
      name: LISTING_LICENSE.CC_BY_NC_SA_40,
      icons: [
        faCreativeCommons,
        faCreativeCommonsBy,
        faCreativeCommonsNc,
        faCreativeCommonsSa
      ],
      description:
        'This license requires that reusers give credit to the creator. It allows reusers to distribute, remix, adapt, and build upon the material in any medium or format, for noncommercial purposes only. If others modify or adapt the material, they must license the modified material under identical terms.',
      learnMoreLabel: 'See the CC BY-NC-SA 4.0 Deed',
      learnMoreLink: 'https://creativecommons.org/licenses/by-nc-sa/4.0/'
    }
  },
  {
    condition: {
      includeProperAttribution: true,
      allowCommercialUses: false,
      allowMod: false,
      allowShareAdaptations: false
    },
    license: {
      name: LISTING_LICENSE.CC_BY_NC_ND_40,
      icons: [
        faCreativeCommons,
        faCreativeCommonsBy,
        faCreativeCommonsNc,
        faCreativeCommonsNd
      ],
      description:
        'This license requires that reusers give credit to the creator. It allows reusers to copy and distribute the material in any medium or format in unadapted form and for noncommercial purposes only.',
      learnMoreLabel: 'See the CC BY-NC-ND 4.0 Deed',
      learnMoreLink: 'https://creativecommons.org/licenses/by-nc-nd/4.0/'
    }
  },
  {
    condition: {
      includeProperAttribution: false,
      allowCommercialUses: true,
      allowMod: true,
      allowShareAdaptations: true
    },
    license: {
      name: LISTING_LICENSE.CC0_10,
      icons: [faCreativeCommons, faCreativeCommonsZero],
      description:
        'By marking the work with a CC0 public domain dedication, the creator is giving up their copyright and allowing reusers to distribute, remix, adapt, and build upon the material in any medium or format, even for commercial purposes.',
      learnMoreLabel: 'See the CC0 1.0 Deed',
      learnMoreLink: 'https://creativecommons.org/publicdomain/zero/1.0/'
    }
  }
]
