import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import '@utils/logger'
import { ENV } from '@common/constants/common'

// disable sentry on localhost
process.env.NODE_ENV !== 'development' &&
  Sentry.init({
    dsn: 'https://987b7b3e9a3442039928aef3493657b0@o4505133978157056.ingest.sentry.io/4505133980712960',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: process.env.REACT_APP_ENV === ENV.PROD ? 0.25 : 1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    environment: process.env.REACT_APP_ENV,
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

reportWebVitals()
