import React from 'react'
import { Box } from '@mui/material'
import ErrorPicturePlaceholder from '@assets/images/no-picture.png'

const Image = ({ src, alt, verticalAlign = 'middle', ref, sx, ...props }) => {
  return (
    <Box
      component="img"
      crossOrigin="anonymous"
      src={src}
      alt={alt}
      ref={ref}
      sx={{ verticalAlign: verticalAlign, ...sx }}
      loading='lazy'
      onError={({ currentTarget }) => {
        currentTarget.src = ErrorPicturePlaceholder
      }}
      {...props}
    />
  )
}

export default Image
