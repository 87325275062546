import { Drawer, Stack, useMediaQuery } from '@mui/material'
import React from 'react'
import { Box } from '@mui/material'
import { TabContext } from '@mui/lab'
import { useFundsContext } from '@common/contexts/FundsContext'
import { FUNDS_TABS } from '@common/contexts/FundsContext/funds.constant'
import DepositForm from './DepositForm'
import WithdrawForm from './WithdrawForm'

export default function TopUpAndWithdrawDrawer({ isOpen, onClose }) {
  const { tab, isLockedView } = useFundsContext()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={(_, reason) => {
          if (
            isLockedView &&
            (reason === 'backdropClick' || reason === 'escapeKeyDown')
          ) {
            return
          }
          onClose()
        }}
        anchor={isMobile ? 'bottom' : 'right'}
      >
        <Box
          sx={{
            width: isMobile ? '100%' : 400,
            maxWidth: '100%',
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}
        >
          <TabContext value={tab}>
            <Stack height="100%">
              {tab === FUNDS_TABS.BUY_PEBL && <DepositForm />}
              {tab === FUNDS_TABS.SELL_PEBL && <WithdrawForm />}
            </Stack>
          </TabContext>
        </Box>
      </Drawer>
    </>
  )
}
