import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { USDollar } from '@utils/common'
import {
  DEPOSIT_DEFAULT_FEE,
  DEPOSIT_FEE
} from '@common/contexts/FundsContext/funds.constant'
import BigNumber from 'bignumber.js'

const PaymentSummary = ({ subTotal, total, fee }) => {
  return (
    <>
      <Box
        sx={{
          border: '1px solid #DADADA',
          borderRadius: 4,
          paddingX: 2,
          paddingTop: 2,
          paddingBottom: 1
        }}
      >
        <Typography variant="h4" sx={{ fontSize: 16, fontWeight: 500 }}>
          Summary
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="caption" sx={{ fontSize: 16, fontWeight: 300 }}>
            Amount
          </Typography>
          <Typography variant="caption" sx={{ fontSize: 16, fontWeight: 500 }}>
            {USDollar.format(subTotal)}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="caption" sx={{ fontSize: 16, fontWeight: 300 }}>
            Transaction fee *
          </Typography>
          <Typography variant="caption" sx={{ fontSize: 16, fontWeight: 500 }}>
            {USDollar.format(fee)}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="caption" sx={{ fontSize: 16, fontWeight: 300 }}>
            Total
          </Typography>
          <Typography
            variant="body2"
            color="error"
            sx={{ fontSize: 20, fontWeight: 500 }}
          >
            {USDollar.format(total)}
          </Typography>
        </Stack>
      </Box>
      <Box padding={2}>
        <Typography
          variant="caption"
          sx={{ fontWeight: 300, fontSize: 14, color: 'rgba(22, 20, 24, 1)' }}
        >
          * {new BigNumber(DEPOSIT_FEE).multipliedBy(100).toString()}% +
          {USDollar.format(DEPOSIT_DEFAULT_FEE)} transaction fee on deposit.
        </Typography>
      </Box>
    </>
  )
}

export default PaymentSummary
