import React, { useMemo, useState } from 'react'
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import {
  DEFAULT_ERROR_MESSAGE,
  GOKOO_TOKEN,
  TOAST_TYPE
} from '@common/constants/common'
import Backdrop from '@components/Backdrop'
import BigNumber from 'bignumber.js'
import { PayoutWithPaypal } from '../PayoutWithPaypal'
import WithdrawSummary from '../WithdrawSummary'
import usePersistState from '@common/hooks/usePersistState'
import axiosInstance from '@utils/axios'
import { OrderTypes, saveOrderInfoLocalStorage } from '@utils/payment.helper'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { useFundsContext } from '@common/contexts/FundsContext'
import { useAllowance } from '@common/hooks/useBalance'
import { ethers } from 'ethers'
import { showToast } from '@utils/toast'
import { WITHDRAW_FEE } from '@common/contexts/FundsContext/funds.constant'
import { useAccount } from 'wagmi'

const Payment = ({ amount }) => {
  const withdrawAmount = amount

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [paying, setPaying] = useState(false)

  const { address } = useAccount()
  const navigate = useNavigate()

  const { openFundsDrawer } = useFundsContext()

  const { approve, getAllowance } = useAllowance({
    owner: address,
    spender: process.env.REACT_APP_MINT_LICENSE_ADDRESS
  })

  const [paypalEmail, setPaypalEmail] = usePersistState({
    name: `PAYPAL_EMAIL/${address}`,
    initialState: ''
  })

  const paymentSummary = useMemo(() => {
    // Set the fee percentage and minimum fee
    // ref: https://chat.google.com/room/AAAAPnn41ok/F-vtDHxBP-8/B1oJENbXGSk?cls=10
    const feeInGOKOO = new BigNumber(WITHDRAW_FEE).div(GOKOO_TOKEN.rate)

    const subTotalInUsd = new BigNumber(withdrawAmount)
      .minus(feeInGOKOO)
      .multipliedBy(GOKOO_TOKEN.rate)
      .toNumber()

    return {
      total: new BigNumber(withdrawAmount).toNumber(),
      totalInUsd: new BigNumber(withdrawAmount)
        .multipliedBy(GOKOO_TOKEN.rate)
        .toNumber(),
      subTotalInUsd: subTotalInUsd >= 0 ? subTotalInUsd : 0,
      fee: WITHDRAW_FEE
    }
  }, [withdrawAmount])

  const handleWithdraw = async () => {
    setPaying(true)
    try {
      const allowance = await getAllowance()
      const isApproved = new BigNumber(
        allowance?._hex || 0
      ).isGreaterThanOrEqualTo(
        new BigNumber(withdrawAmount).multipliedBy(10 ** GOKOO_TOKEN.decimals)
      )

      if (!isApproved) {
        await approve(
          process.env.REACT_APP_MINT_LICENSE_ADDRESS,
          ethers.constants.MaxUint256
        )
      }

      const decimalAmount = new BigNumber(withdrawAmount)
        .multipliedBy(10 ** GOKOO_TOKEN.decimals)
        .toString()

      const res = await axiosInstance.post('/withdrawGkoo', {
        email: paypalEmail,
        amount: decimalAmount
      })

      if (res.data?.txHash && res.status === 200) {
        const orderId = uuidv4()

        saveOrderInfoLocalStorage(orderId, OrderTypes.withdraw, {
          withdrawAmount,
          receiveAmountInUsd: paymentSummary.subTotalInUsd,
          email: paypalEmail,
          txHash: res.data?.txHash
        })

        navigate(`/confirmation/withdraw/${orderId}`)
        openFundsDrawer(false)
      } else {
        showToast(res.error?.message || DEFAULT_ERROR_MESSAGE, TOAST_TYPE.ERROR)
      }
    } catch (e) {
      console.log(e)
      showToast(e?.reason ?? DEFAULT_ERROR_MESSAGE, TOAST_TYPE.ERROR)
    }
    setPaying(false)
  }

  return (
    <>
      <Box
        sx={{
          width: isMobile ? '100%' : 400,
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <>
          <Box marginBottom={8}>
            <Typography variant="h3">Select Payment Method</Typography>
            <Stack spacing={2} mt={4}>
              <PayoutWithPaypal
                paypalEmail={paypalEmail}
                setPaypalEmail={setPaypalEmail}
              />
            </Stack>
          </Box>

          <Box>
            <WithdrawSummary
              total={paymentSummary.total}
              totalInUsd={paymentSummary.totalInUsd}
              subTotalInUsd={paymentSummary.subTotalInUsd}
              fee={paymentSummary.fee}
            />

            <Button
              variant="contained"
              color="success"
              size="large"
              disabled={!paypalEmail}
              onClick={() => {
                handleWithdraw()
              }}
              sx={{
                lineHeight: 1.4,
                minWidth: '100%',
                backgroundColor: 'success.main',
                ':hover': {
                  backgroundColor: 'success.dark'
                },
                cursor: 'pointer'
              }}
            >
              <Box
                component="span"
                sx={{
                  marginRight: 1,
                  fontSize: 20
                }}
              >
                Check out
              </Box>
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </Box>
        </>

        {paying && (
          <Backdrop
            open={paying}
            loading={paying}
            message={
              <p style={{ textAlign: 'center' }}>
                Please don&apos;t close or refresh this window during the
                payment process.
              </p>
            }
          />
        )}
      </Box>
    </>
  )
}

export default Payment
