window.console = (function (origConsole) {
  if (!window.console || !origConsole) {
    origConsole = {}
  }

  let isDebug = localStorage.getItem('ENABLE_DEBUGGER') === 'true'
  let isSaveLog = true
  let limitSaveLogs = 100
  let allLogs = {
    logs: [],
    errors: [],
    warns: [],
    infos: [],
    tables: []
  }

  return {
    debug: function (bool) {
      isDebug = bool
      if (bool) {
        localStorage.setItem('ENABLE_DEBUGGER', true)
      } else {
        localStorage.removeItem('ENABLE_DEBUGGER')
      }
    },
    saveLog: function (bool) {
      isSaveLog = bool
    },
    clear: function () {
      allLogs = {
        logs: [],
        errors: [],
        warns: [],
        infos: [],
        tables: []
      }
      isDebug && origConsole.clear && origConsole.clear()
    },
    addLog: function (logArg, array) {
      if (!isSaveLog) {
        return
      }
      allLogs[array || 'logs'].push(logArg[0])
      allLogs[array || 'logs'] = allLogs[array || 'logs'].slice(-limitSaveLogs)
    },
    setLimit: function (limit) {
      if (limit <= 0) return
      limitSaveLogs = limit
    },
    allLogs: function () {
      return allLogs
    },
    // override clg funcs
    log: function () {
      this?.addLog?.(arguments, 'logs')
      isDebug &&
        origConsole.log &&
        origConsole.log.apply(origConsole, arguments)
    },
    table: function () {
      this?.addLog?.(arguments, 'tables')
      isDebug &&
        origConsole.table &&
        origConsole.table.apply(origConsole, arguments)
    },
    warn: function () {
      this?.addLog?.(arguments, 'warns')
      isDebug &&
        origConsole.warn &&
        origConsole.warn.apply(origConsole, arguments)
    },
    error: function () {
      this?.addLog?.(arguments, 'errors')
      isDebug &&
        origConsole.error &&
        origConsole.error.apply(origConsole, arguments)
    },
    info: function (v) {
      this?.addLog?.(arguments, 'infos')
      isDebug &&
        origConsole.info &&
        origConsole.info.apply(origConsole, arguments)
    }
  }
})(window.console)
