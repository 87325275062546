import React from 'react'
import { ToastContainer } from 'react-toastify'
import DefaultLayout from './DefaultLayout'
import { HelmetProvider } from 'react-helmet-async'

const Layout = ({ children }) => {
  return (
    <HelmetProvider>
      <DefaultLayout>
        <ToastContainer theme="colored" />
        <>{children}</>
      </DefaultLayout>
    </HelmetProvider>
  )
}

export default Layout
