import React from 'react'

const CheckboxIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect
      width="22"
      height="22"
      x="1"
      y="1"
      stroke="currentColor"
      strokeOpacity="0.5"
      rx="8"
    />
  </svg>
)

export default CheckboxIcon
