import { GOKOO_TOKEN } from '@common/constants/common'
import { Box, Stack, useTheme } from '@mui/material'
import React from 'react'
import { GKOOIcon } from './icons/GKOOIcon'

export default function GKOORate() {
  const theme = useTheme()

  return (
    <Stack justifyContent="center" alignItems="flex-end">
      <Box
        color={theme.palette.grey[800]}
        sx={{ fontSize: '15px !important', lineHeight: '13px' }}
      >
        $1.00 =
        <Box display="inline" mx="2px">
          <GKOOIcon size={15} mt="-2px" />
        </Box>
        {1 / GOKOO_TOKEN.rate}
      </Box>
    </Stack>
  )
}
