import React from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import SocialLinkIcon from '@features/homepage/SocialLinkIcon'
import { useAppTheme } from '@features/theme/AppTheme'
import { getPublicAssetUrl } from '@utils/common'

const Footer = () => {
  const { themeMode } = useAppTheme()
  const theme = useTheme()

  return (
    <Box
      id="footer"
      component="footer"
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        padding: {
          xs: '2.5rem 2rem 2.5rem 2rem',
          sm: '3rem 2rem',
          lg: '1.625rem 4.4375rem'
        },
        bgcolor:
          themeMode === 'dark'
            ? theme.palette.black[500]
            : theme.palette.white[300]
      }}
    >
      <Grid
        container
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          rowGap: 1
        }}
      >
        <Grid item xs={12} md="auto">
          <Grid
            container
            sx={{
              columnGap: {
                xs: 1,
                sm: 2
              }
              // [mobileLandscapeQuery]: {
              //   columnGap: '0.5rem !important'
              // },
              // alignItems: 'center'
            }}
          >
            <Grid item>
              <SocialLinkIcon
                src={getPublicAssetUrl('/icons/twitter-dark.svg')}
                to="https://twitter.com/mogaokoo"
              />
            </Grid>

            <Grid item>
              <SocialLinkIcon
                src={getPublicAssetUrl('/icons/facebook.svg')}
                to="https://www.facebook.com/mogaokoo/"
              />
            </Grid>

            <Grid item>
              <SocialLinkIcon
                src={getPublicAssetUrl('/icons/instagram-dark.svg')}
                to="https://www.instagram.com/mogaokoo/"
              />
            </Grid>

            <Grid item>
              <SocialLinkIcon
                src={getPublicAssetUrl('/icons/medium-dark.svg')}
                to="https://mogaokoo.medium.com/"
              />
            </Grid>

            <Grid item>
              <SocialLinkIcon
                src={getPublicAssetUrl('/icons/weibo.svg')}
                to="https://weibo.com/mogaokoo"
              />
            </Grid>

            <Grid item>
              <SocialLinkIcon
                src={getPublicAssetUrl('/icons/tiktok.svg')}
                to="https://www.douyin.com/user/MS4wLjABAAAACpkgh3IL6fV9WgdYhlUuX8p_BSYh_bmSNS2tLNY4MOc"
              />
            </Grid>

            <Grid item>
              <SocialLinkIcon
                src={getPublicAssetUrl('/icons/bilibili.svg')}
                to="https://space.bilibili.com/3546612244679138/"
              />
            </Grid>
            {/* <Grid item>
              <LanguageSelector />
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item xs={12} md="auto">
          <Typography
            component="p"
            sx={{
              fontSize: { xs: 16 },
              lineHeight: 1,
              fontWeight: 400,
              marginTop: {
                xs: 4.5,
                sm: 2.5
              },
              textAlign: { md: 'end' }
            }}
          >
            A Decentralized Marketplace for Digital Goods
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Footer
