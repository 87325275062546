import { useCallback, useMemo, useState } from 'react'
import { GOKOO_TOKEN } from '@common/constants/common'
import { useAccount, useReadContract, useWalletClient } from 'wagmi'
import { erc20Abi, formatUnits } from 'viem'
import { sendMetaTransaction } from '@utils/meta-transaction'
import { waitForReceipt } from '@utils/ethers'

export const useGOKOOBalance = (wallet) => {
  const { data, isLoading, error } = useReadContract({
    abi: erc20Abi,
    address: GOKOO_TOKEN.address,
    functionName: 'balanceOf',
    args: [wallet]
  })

  return {
    data,
    isLoading: wallet && isLoading,
    error
  }
}

export const useAllowance = ({ owner, spender }) => {
  const {
    data: allowance,
    isLoading,
    error
  } = useReadContract({
    abi: erc20Abi,
    address: GOKOO_TOKEN.address,
    functionName: 'allowance',
    args: [owner, spender],
    query: {
      enabled: owner && spender
    }
  })

  const [isApproving, setIsApproving] = useState(false)

  const { address } = useAccount()

  const { data: walletClient } = useWalletClient({
    account: address
  })

  const approve = useCallback(
    async (spender, value) => {
      try {
        setIsApproving(true)
        const receipt = await sendMetaTransaction({
          from: owner,
          to: GOKOO_TOKEN.address,
          functionName: 'approve',
          abi: erc20Abi,
          args: [spender, value],
          walletClient
        })
        console.log('receipt', receipt?.txHash)
        await waitForReceipt(receipt?.txHash)
      } catch (e) {}
      setIsApproving(false)
    },
    [owner, walletClient]
  )

  const getAllowance = useCallback(async () => {
    return allowance
  }, [allowance])

  const isValidData = owner && spender

  return {
    allowance: (isValidData && allowance) || 0,
    isLoading: isValidData && isLoading,
    isApproving,
    approve,
    error,
    getAllowance
  }
}

export const useErc20Balance = ({ account, contractAddress }) => {
  const {
    data: balance,
    isLoading,
    error,
    refetch
  } = useReadContract({
    abi: erc20Abi,
    address: contractAddress,
    functionName: 'balanceOf',
    args: [account],
    query: {
      enabled: account && account.trim().length > 0
    }
  })

  const data = useMemo(() => {
    return {
      decimals: GOKOO_TOKEN.decimals,
      value: balance ?? 0n,
      displayValue: formatUnits(balance ?? 0n, GOKOO_TOKEN.decimals)
    }
  }, [balance])

  return {
    data,
    refetch,
    isLoading,
    loadingBalanceError: error
  }
}
