import React from 'react'
import { Paper, Typography, Box, Stack, Checkbox } from '@mui/material'
import Image from '@components/Image'
import CheckboxIcon from '@components/icons/CheckboxIcon'
import CheckedCheckboxIcon from '@components/icons/CheckedCheckboxIcon'
import { useTheme } from '@emotion/react'
import { getPublicAssetUrl } from '@utils/common'

const PaymentLineItemETH = ({ checked, onLineChecked, disabled }) => {
  return (
    <Stack direction="row" alignItems="center">
      <Checkbox
        checked={checked}
        onClick={onLineChecked}
        icon={<CheckboxIcon width={20} height={20} />}
        checkedIcon={<CheckedCheckboxIcon width={20} height={20} />}
        disabled={disabled}
      />
      <Paper
        sx={{
          flex: 1,
          paddingX: { xs: 2, md: 5 },
          paddingY: 3,
          borderRadius: 2,
          backgroundColor: '#F9F9FA'
        }}
      >
        <Box direction="row" display="flex">
          <Box>
            <Image src={getPublicAssetUrl('/icons/usdc.svg')} />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ marginLeft: '22px' }}
            justifyContent="center"
          >
            <Typography variant="body2Medium" sx={{ fontSize: 16 }}>
              Pay with ETH
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Stack>
  )
}

const PaymentLineItemUSD = ({ checked, onLineChecked, disabled }) => {
  const theme = useTheme()

  return (
    <Stack direction="row" alignItems="center">
      <Paper
        sx={{
          cursor: 'pointer',
          flex: 1,
          paddingX: { xs: 2, md: 3 },
          paddingY: 2,
          borderRadius: 4,
          backgroundColor: theme.palette.white[300],
          border: '3px solid transparent',
          opacity: disabled ? 0.5 : 1,
          borderColor: disabled
            ? theme.palette.white[600]
            : checked
            ? '#fa4242cf'
            : 'transparent'
        }}
        onClick={onLineChecked}
      >
        <Box direction="row" display="flex" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="column"
            sx={{ marginLeft: '22px' }}
            justifyContent="center"
          >
            <Typography variant="body2Medium" sx={{ fontSize: 16 }}>
              Pay with Credit Card
            </Typography>
          </Box>
          <Box justifyContent="center">
            <Image src={getPublicAssetUrl('/icons/card.svg')} />
          </Box>
        </Box>
      </Paper>
    </Stack>
  )
}

const PaymentLineItemCNY = ({ checked, onLineChecked, disabled }) => {
  const theme = useTheme()

  return (
    <Stack direction="row" alignItems="center">
      <Paper
        sx={{
          cursor: 'pointer',
          flex: 1,
          paddingX: { xs: 2, md: 3 },
          paddingY: 2,
          borderRadius: 4,
          backgroundColor: theme.palette.white[300],
          border: '3px solid transparent',
          opacity: disabled ? 0.5 : 1,
          borderColor: disabled
            ? theme.palette.white[600]
            : checked
            ? '#fa4242cf'
            : 'transparent'
        }}
        onClick={onLineChecked}
      >
        <Box direction="row" display="flex" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="column"
            sx={{ marginLeft: '22px' }}
            justifyContent="center"
          >
            <Typography variant="body2Medium" sx={{ fontSize: 16 }}>
              Pay with Alipay
            </Typography>
          </Box>
          <Box justifyContent="center">
            <Image src={getPublicAssetUrl('/icons/alipay.svg')} />
          </Box>
        </Box>
      </Paper>
    </Stack>
  )
}

export { PaymentLineItemETH, PaymentLineItemUSD, PaymentLineItemCNY }
