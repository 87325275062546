import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { WITHDRAW_FEE } from '@common/contexts/FundsContext/funds.constant'
import { USDollar } from '@utils/common'
import { GKOOIcon } from '@components/icons/GKOOIcon'

const WithdrawSummary = ({ total, totalInUsd, fee, subTotalInUsd }) => {
  return (
    <>
      <Box
        sx={{
          border: '1px solid #DADADA',
          borderRadius: 4,
          paddingX: 2,
          paddingTop: 2,
          paddingBottom: 1
        }}
      >
        <Typography variant="h4" sx={{ fontSize: 16, fontWeight: 500 }}>
          Summary
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
          spacing={2}
        >
          <Typography variant="caption" sx={{ fontSize: 16, fontWeight: 300 }}>
            Withdraw amount
          </Typography>
          <Typography
            variant="caption"
            sx={{ fontSize: 16, fontWeight: 500, textAlign: 'right' }}
          >
            <GKOOIcon size={18} /> {total}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
          spacing={2}
        >
          <Typography variant="caption" sx={{ fontSize: 16, fontWeight: 300 }}>
            Total Value
          </Typography>
          <Typography
            variant="caption"
            sx={{ fontSize: 16, fontWeight: 500, textAlign: 'right' }}
          >
            {USDollar.format(totalInUsd)}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="caption" sx={{ fontSize: 16, fontWeight: 300 }}>
            Transaction fee *
          </Typography>
          <Typography variant="caption" sx={{ fontSize: 16, fontWeight: 500 }}>
            {USDollar.format(fee)}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="caption" sx={{ fontSize: 16, fontWeight: 300 }}>
            Net Payout
          </Typography>
          <Typography
            variant="body2"
            color="error"
            sx={{ fontSize: 20, fontWeight: 500 }}
          >
            {USDollar.format(subTotalInUsd)}
          </Typography>
        </Stack>
      </Box>
      <Box padding={2}>
        <Typography
          variant="caption"
          sx={{ fontWeight: 300, fontSize: 14, color: 'rgba(22, 20, 24, 1)' }}
        >
          {USDollar.format(WITHDRAW_FEE)} flat transaction fee on withdraw.
        </Typography>
      </Box>
    </>
  )
}

export default WithdrawSummary
