import React, { Suspense, lazy, useEffect, useState } from 'react'
import Layout from './layouts'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.min.css'
import { routes } from '@routes/index'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import AppTheme from '@features/theme/AppTheme'
import { WalletProvider } from '@features/wallet/WalletContext'
import dayjs from 'dayjs'
import { FundsProvider } from '@common/contexts/FundsContext'
import { LinearProgress } from '@mui/material'

import '@utils/mixpanel'
import '@utils/google-tag-manager'
import '@utils/date'

import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/navigation'
import { StaticAssetsProvider } from '@common/contexts/StaticAssetsContext'

// config worker to read pdf

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { wagmiConfig } from '@utils/wagmi-config'
import { WagmiProvider } from 'wagmi'

const Alert = lazy(() => import('@mui/material/Alert'))
const Snackbar = lazy(() => import('@mui/material/Snackbar'))

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_THEGRAPH_API_BASE,
  cache: new InMemoryCache()
})

export const dAppConfig = {
  chainId: parseInt(process.env.REACT_APP_CHAIN_ID),
  chainName: process.env.REACT_APP_CHAIN_NAME,
  rpcUrl: process.env.REACT_APP_RPC_URL,
  nativeCurrency: {
    name: process.env.REACT_APP_NATIVE_CURRENCY,
    symbol: process.env.REACT_APP_NATIVE_CURRENCY,
    decimals: process.env.REACT_APP_NATIVE_CURRENCY_DECIMALS || 18
  },
  blockExplorerUrl: process.env.REACT_APP_BLOCK_EXPLORER_URL
}

const renderRoute = (route) => (
  <Route
    key={route.path}
    path={route.path}
    element={route.element}
    index={route.index}
  >
    {Array.isArray(route.children) &&
      route.children.length > 0 &&
      route.children.map((child) => renderRoute(child))}
  </Route>
)

const queryClient = new QueryClient()

const App = () => {
  const [openAlert, setOpenAlert] = useState(false)

  useEffect(() => {
    fetch(process.env.REACT_APP_IPFS_SAMPLE).catch(() => {
      setOpenAlert(true)
    })
  }, [])

  const handleClose = () => {
    setOpenAlert(false)
  }

  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <AppTheme>
          <StaticAssetsProvider>
            <WagmiProvider config={wagmiConfig}>
              <QueryClientProvider client={queryClient}>
                <WalletProvider>
                  <FundsProvider>
                    <Layout>
                      <Suspense fallback={<LinearProgress />}>
                        <Routes>
                          {routes.map((route) => renderRoute(route))}
                          <Route
                            path="*"
                            element={<Navigate to="/404" replace />}
                          />
                        </Routes>
                      </Suspense>
                    </Layout>
                  </FundsProvider>
                </WalletProvider>
              </QueryClientProvider>
            </WagmiProvider>
          </StaticAssetsProvider>
        </AppTheme>
      </Router>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          The current network blocks access to IPFS and media files fail to
          load. Please try on another network.
        </Alert>
      </Snackbar>
    </ApolloProvider>
  )
}

export default App
