import { createConfig, createStorage } from 'wagmi'
import { createPublicClient, http } from 'viem'
import { bscTestnet, polygon } from 'viem/chains'
import { ENV } from '@common/constants/common'
import { dedicatedWalletConnector } from './magiclink-connector'

export const currentChain = [ENV.PROD, ENV.QA].includes(
  process.env.REACT_APP_ENV
)
  ? polygon
  : bscTestnet

export const wagmiStorage = createStorage({ storage: localStorage })

export const magicConnector = dedicatedWalletConnector({
  chains: [currentChain],
  options: {
    apiKey: process.env.REACT_APP_API_KEY_CONNECT_MAGIC_LINK,
    magicSdkConfiguration: {
      network: {
        rpcUrl: process.env.REACT_APP_PUBLIC_RPC,
        chainId: currentChain.id
      }
    }
  }
})

export const wagmiConfig = createConfig({
  chains: [currentChain],
  transports: {
    [currentChain.id]: http(process.env.REACT_APP_PUBLIC_RPC)
  },
  connectors: [magicConnector]
})

export const publicClient = createPublicClient({
  chain: currentChain,
  transport: http(process.env.REACT_APP_PUBLIC_RPC)
})
