import React from 'react'
import { useFundsContext } from '@common/contexts/FundsContext'
import { Tab } from '@mui/material'
import { FUNDS_TABS } from '@common/contexts/FundsContext/funds.constant'
import { TabList } from '@mui/lab'

export default function FundsTabList() {
  const { setTab } = useFundsContext()
  return (
    <TabList
      onChange={(_, tab) => {
        setTab(tab)
      }}
      variant="fullWidth"
      height="100%"
      sx={{ marginBottom: 2 }}
    >
      <Tab label="Deposit" value={FUNDS_TABS.BUY_PEBL} />
      <Tab label="Withdraw" value={FUNDS_TABS.SELL_PEBL} />
    </TabList>
  )
}
