import { useCallback, useState } from 'react'

const prefixPersistStateName = '@koi/'

const getPersistStateName = (name) => {
  return `${prefixPersistStateName}${name}`
}

export default function usePersistState({ name, initialState }) {
  const [persistState, _setPersistState] = useState(() => {
    try {
      const storedState =
        JSON.parse(
          localStorage.getItem(
            getPersistStateName(name) || JSON.stringify(initialState)
          )
        ) || initialState
      return storedState
    } catch (e) {
      console.log({ e })
      return initialState
    }
  })

  const setPersistState = useCallback(
    (newState) => {
      localStorage.setItem(getPersistStateName(name), JSON.stringify(newState))
      _setPersistState(newState)
    },
    [name]
  )

  return [persistState, setPersistState]
}
