export const ENV = {
  DEV: 'dev',
  QA: 'qa',
  PROD: 'prod'
}

export const TOAST_TYPE = {
  ERROR: 'error',
  DEFAULT: 'default',
  SUCCESS: 'success',
  INFO: 'info'
}

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong!'

export const TOAST_DEFAULT_MESSAGE = {
  SUCCESS: 'Success',
  FAILED: 'An error occurred'
}

export const TRANSACTION_STATE = {
  ERROR: 'error',
  LOADING: 'loading',
  SUCCESS: 'success'
}

export const NAVIGATE_TYPE = {
  ROUTE: 'route',
  EXT_LINK: 'extLink'
}

export const BREAKPOINT = {
  XS: 'xs',
  SM: 'sm'
}

export const CURRENCY = {
  USDT: 'USDT',
  MATIC: 'MATIC'
}

export const TOKEN_DECIMALS = {
  USDT: 6,
  MATIC: 18
}

export const IGNORED_ERRORS = ['Unsupported chain']

export const QUANTITY_ADJUST_TYPE = {
  INCREMENT: 'increment',
  DECREMENT: 'decrement'
}

export const LISTING_STEP = {
  FILE_SETTINGS: 'Assets',
  BASIC_INFO: 'Basic info',
  RIGHTS: 'Rights',
  LICENSING: 'Terms',
  PRICING: 'Pricing',
  SUMMARY: 'Summary'
}

export const LISTING_LICENSE = {
  CC_BY_40: 'CC BY 4.0',
  CC_BY_SA_40: 'CC BY-SA 4.0',
  CC_BY_ND_40: 'CC BY-ND 4.0',
  CC_BY_NC_40: 'CC BY-NC 4.0',
  CC_BY_NC_SA_40: 'CC BY-NC-SA 4.0',
  CC_BY_NC_ND_40: 'CC BY-NC-ND 4.0',
  CC0_10: 'CC0 1.0'
}

export const MAX_FILE_SIZE = {
  CREATIVE_FILE: 5 * 1024 * 1024 * 1024, // 5GB
  RELEASE_DOC: 100 * 1024 * 1024, // 100MB
  LICENSE_DOC: 100 * 1024 * 1024 * 1024 // 100MB
}

export const FORM_MODE = {
  CREATE: 'create',
  EDIT: 'edit'
}

export const SOCIAL_LINK = {
  TELEGRAM: 'https://t.me',
  DISCORD: 'https://discordapp.com/users',
  INSTAGRAM: 'https://instagram.com',
  TWITTER: 'https://twitter.com'
}

export const APPROVAL_TYPE = {
  CURRENCY: 'currency',
  PURCHASE: 'purchase'
}

export const btnSizing = {
  height: 'inherit !important',
  padding: {
    xs: '7px 2px !important',
    sm: '7px 21px !important'
  },
  fontSize: '0.9375rem !important'
}

export const btnXs120 = {
  ...btnSizing,
  minWidth: {
    xs: '120px !important',
    sm: '120px !important'
  }
}

export const btnXs140 = {
  ...btnSizing,
  minWidth: {
    xs: '140px !important',
    sm: '120px !important'
  }
}

export const CURRENT_USER_ACCESS_TOKEN = 'CURRENT_USER_ACCESS_TOKEN'

export const GOKOO_TOKEN = {
  symbol: 'GOKOO',
  decimals: 6,
  address: process.env.REACT_APP_GKOO_TOKEN_ADDRESS,
  rate: 0.01
}

export const FREEMIUM_MAX_STORAGE_SIZE = 1024 * 1024 * 1024 // 1GB
export const FREEMIUM_MAX_FILE_SIZE = 150 * 1024 * 1024 // 150MB
export const PRO_MAX_FILE_SIZE = 500 * 1024 * 1024 // 500 MB

export const ACCOUNT_TIER = {
  FREE: 'Free',
  PRO: 'Pro'
}

export const MEDIA_MIME_TYPES = [
  // Audio MIME types
  'audio/aac',
  'audio/midi',
  'audio/x-midi',
  'audio/mpeg',
  'audio/ogg',
  'audio/opus',
  'audio/wav',
  'audio/webm',
  'audio/3gpp',
  'audio/3gpp2',
  'audio/mp4',
  'audio/x-wav',
  'audio/x-pn-wav',
  'audio/vnd.dlna.adts',
  'audio/vnd.dts',
  'audio/vnd.dts.hd',
  'audio/vnd.rn-realaudio',
  'audio/vnd.wave',
  'audio/vnd.audible.aax',
  'audio/flac',
  'audio/aiff',
  'audio/basic',

  // Video MIME types
  'video/x-msvideo',
  'video/x-matroska',
  'video/x-flv',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/webm',
  'video/3gpp',
  'video/3gpp2',
  'video/quicktime',
  'video/x-ms-wmv',
  'video/x-msvideo',
  'video/vnd.dlna.mpeg-tts',
  'video/x-m4v',
  'video/x-mng',
  'video/x-ms-asf',
  'video/x-ms-vob',
  'video/x-f4v',
  'video/avi',
  'video/mp2t'
]

export const IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/tiff',
  'image/svg+xml',
  'image/webp',
  'image/x-icon',
  'image/heif'
]

export const TOAST_FILENAME_MAX_LENGTH = 28

export const LISTING_DELIVERY_TYPE = {
  DOWNLOAD: 'download',
  STREAM: 'stream'
}
