import React, { createContext, useContext, useState } from 'react'
import TopUpAndWithdrawDrawer from './components/TopUpAndWithdrawDrawer'

const FundsContext = createContext({
  tab: null,
  setTab: () => {},
  goBackUrl: '',
  setGoBackUrl: () => {},
  isLockedView: '',
  lockView: () => {},
  openFundsDrawer: () => {}
})

export const useFundsContext = () => {
  return useContext(FundsContext)
}

export function FundsProvider({ children }) {
  const [isOpen, setIsOpen] = React.useState()
  const [tab, setTab] = useState(null)
  const [isLockedView, setIsLockedView] = useState(null)
  const [goBackUrl, setGoBackUrl] = React.useState('')

  return (
    <FundsContext.Provider
      value={{
        tab,
        setTab,
        isLockedView,
        lockView: (state) => {
          setIsLockedView(Boolean(state))
        },
        openFundsDrawer: (tab) => {
          setIsOpen(Boolean(tab))
          setTab(tab || null)
        },
        goBackUrl,
        setGoBackUrl
      }}
    >
      {children}
      {isOpen && (
        <TopUpAndWithdrawDrawer
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false)
          }}
        />
      )}
    </FundsContext.Provider>
  )
}
